import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment'
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { Quote } from '../../../interface/Quote';
import { useQuoteSearchFilter } from '../../../hooks/useQuoteSearchFilter';
import { getInitials } from '../../../util/getInitials';
import { ImagePreview } from '../../../components/ImagePreview';

interface CustomerQuotesTableProps
{
  quotes: Quote[] | undefined
  onQuoteClick: (product: Quote) => void
}

export const CustomerQuotesTable = ({ quotes, onQuoteClick }: CustomerQuotesTableProps) => {

  const [ query, setQuery ] = useState()
  const filtered = useQuoteSearchFilter(quotes, query)
  
  return (
    <>
    <TextField value={query} style={styles.search} fullWidth placeholder="Search Customer Quotes" onChange={(e: any) => setQuery(e.target.value)}/>
    <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left">Customer</TableCell>
            <TableCell align="left">Product Title</TableCell>
            <TableCell align="right">Description</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Created</TableCell>
            <TableCell align="right">Images</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {filtered?.map((row) => (
            <TableRow
              onClick={() => onQuoteClick(row)}
              hover
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              
               <TableCell component="th" scope="row" >
                <Avatar style={styles.avatar}>{getInitials(row.customerEmail)}</Avatar>
               </TableCell>
              <TableCell component="th" scope="row" >{row.customerName || row.customerEmail || "-"}</TableCell>
              <TableCell align="left">{row.productName || "-"}</TableCell>
              <TableCell align="right">{row.quoteItems.map(item => item.title).join(",")}</TableCell>
              <TableCell align="right">${row.cost.toFixed(2)}</TableCell>
              <TableCell align="right" >{moment(row.createdAt).fromNow()}</TableCell>
              <TableCell align="right" ><ImagePreview images={row.images}/></TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>

  );
}

const styles = {
  firstItem: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    flexDirection: 'row' as 'row',
  },
  avatar: {
    marginRight: 6,
    backgroundColor: "#7986CB"
  },
  search: {
    marginBottom: 12,
    marginTop: 6,
  }
}