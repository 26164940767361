import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import { useEffect, useState } from "react"
import IconButton from "@mui/material/IconButton"
import AddIcon from '@mui/icons-material/Add'
import { Invoice, InvoiceProductItem, InvoiceServiceItem } from "../../interface/Invoice"
import { InvoiceApi } from "../../api/InvoiceApi"
import { User } from "../../interface/User"
import { UserApi } from "../../api/UserApi"
import Select from "@mui/material/Select"
import { MenuItem, Typography } from "@mui/material"
import { ProductRow } from "./ProductRow"
import { v4 as uuid } from 'uuid'
import { ServiceRow } from "./ServiceRow"

interface CreateInvoiceModalProps
{
  open: boolean
  handleClose: () => void
  onAddSuccess: (invoice: Invoice) => void
}

export const CreateInvoiceModal = ({ open, handleClose, onAddSuccess }: CreateInvoiceModalProps) =>
{

  const [ isAdding, setAdding ] = useState(false)
  const [ isGettingCustomers, setGettingCustomers ] = useState(false)
  const [ customers, setCustomers ] = useState<User[] | undefined>()
  const [ customer, setCustomer ] = useState<User | undefined>()

  const [ products, setProducts ] = useState<InvoiceProductItem[]>([])
  const [ services, setServices ] = useState<InvoiceServiceItem[]>([])

  const [ title, setTitle ] = useState("")
  const [ description, setDescription ] = useState("")  

  useEffect(() => {

    getCustomers()

  }, [])

  const addInvoice = async () =>
  {
    try
    { 
      if(!customer?._id) return

      setAdding(true)
      const invoice = await new InvoiceApi().createInvoice({ title, description, userId: customer?._id, discount: 0, products: products || [], services: services || [], isPaid: false })
      if(invoice) onAddSuccess(invoice)
      setAdding(false)
    }
    catch(error)
    {
      setAdding(false)
    }
  }

  const getCustomers = async () =>
  {
    try
    {

      setGettingCustomers(true)
      const customers = await new UserApi().getUsers("customer")
      setCustomers(customers)
      setGettingCustomers(false)
    }
    catch(error)
    {
      setGettingCustomers(false)
    }
  }

  const addProductRow = () =>
  {
      setProducts([...products, { localId: uuid(), description: "", amount: 0, quantity: 0 }])
  }

  const removeProductItem = (localId: string) =>
  {
    setProducts(products.filter(p => p.localId !== localId))
  }

  const updateProductRow = (product: InvoiceProductItem) =>
  {
    const updated = products.map(p => {
      if(p.localId === product.localId) return product;
      return p
    })

    setProducts(updated)
  }

  const addServiceRow = () =>
  {
      setServices([...services, { localId: uuid(), description: "", amount: 0, duration: 0 }])
  }

  const removeServiceRow = (_id?: string, localId?: string) =>
  {
    setServices(services.filter(s => s.localId !== localId))
  }

  const updateServiceRow = (service: InvoiceServiceItem) =>
  {
    const updated = services.map(p => {
      if(p.localId === service.localId) return service;
      return p
    })

    setServices(updated)
  }

  const getInvoiceTotal = () =>
  {
    let total = 0;
    for(const product of products)
    {
      total = total + (product.amount * product.quantity)
    }

    for(const service of services)
    {
      total = total + (service.amount * service.duration)
    }

    return total
  }

  const getGST = () =>
  {
    return (getInvoiceTotal() * 0.1).toFixed(2)
  }

  return(<Dialog
    fullWidth
    maxWidth="md"
    open={open}
    onClose={handleClose}>

    <DialogTitle>Create invoice for customer</DialogTitle>

    <DialogContent>

      <Grid container flexDirection="column" spacing={2}> 

      <Grid item>
        <TextField autoFocus placeholder="Invoice Title" fullWidth value={title} onChange={(e: any) => setTitle(e.target.value)}/>
      </Grid>


      <Grid item>
        <TextField size="small" placeholder="Invoice Description" fullWidth value={description} onChange={(e: any) => setDescription(e.target.value)}/>
      </Grid>

      <Grid item>
        <Typography color="textSecondary">Select customer or person</Typography>
        <Select fullWidth value={customer?._id} >
          {customers?.map((customer, key) => <MenuItem key={key} onClick={() => setCustomer(customer)} value={customer._id}>{customer.email}</MenuItem>)}
        </Select>
      </Grid>

      <Grid item>

        <Typography color="textSecondary">Products</Typography>
        <IconButton onClick={addProductRow}><AddIcon /></IconButton>
        { products?.map((p, key) => <ProductRow key={key} product={p} onRemove={removeProductItem} updateProductRow={updateProductRow} />  )}
      </Grid>

      <Grid item>

        <Typography color="textSecondary">Services</Typography>
        <IconButton onClick={addServiceRow}><AddIcon /></IconButton>
        { services?.map((s, key) => <ServiceRow key={key} service={s} onRemove={removeServiceRow} updateServiceRow={updateServiceRow} />  )}
      </Grid>

      <Typography fontWeight="700" fontSize={32} textAlign="end" sx={{ m: 1}}>Invoice Total: ${getInvoiceTotal().toFixed(2)}</Typography>
      <Typography color="textSecondary" fontWeight="600" fontSize={16} textAlign="end" sx={{ m: 1}}>GST amount: ${getGST()}</Typography>
      
      </Grid>
      
    </DialogContent>

    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
      <Button variant="contained" disabled={isAdding || !customer} startIcon={isAdding ? <CircularProgress size={16} /> : <AddIcon />} onClick={addInvoice}>{ isAdding ? "Creating" : "Create"}</Button>
    </DialogActions>
    
  </Dialog>)
}
