import { Product } from "../interface/Product";
import { ResponseQuoteItemItem } from "../interface/ResponseQuoteItem";
import { HttpService } from "./HttpService";


export class ProductApi extends HttpService
{
  getProducts = async (type?: string) =>
  {
    const response = await this.request<Product[]>("GET", `products?type=${type}`)
    return response?.data
  }

  getQuoteItemsforProduct = async (productId: string) =>
  {
    const response = await this.request<ResponseQuoteItemItem[]>("GET", `products/quoteItem?productId=${productId}`)
    return response?.data
  }

  updateProduct = async (product: Product) =>
  {
    const response = await this.request<Product>("PATCH", `products?productId=${product._id}`, product)
    return response?.data
  }

  addProduct = async (product: Product) =>
  {
    const response = await this.request<Product>("POST", `products`, product)
    return response?.data
  }

  deleteProduct = async (productId: string) =>
  {
    const response = await this.request<Product>("DELETE", `products?productId=${productId}`)
    return response?.data
  }
}