import { useEffect, useState } from "react"
import { User } from "../interface/User"
import { UserApi } from "../api/UserApi"
import { CustomersTable } from "./customers/CustomersTable"
import Fab from "@mui/material/Fab"
import AddIcon from '@mui/icons-material/Add'
import { AddCustomerModal } from "./customers/AddCustomerModal"
import { ConfirmModal } from "../components/ConfirmModal"
import { LoadingPlaceholder } from "../components/LoadingPlaceholder"


export const Users = () =>
{

  const [ users, setUsers ] = useState<User[]>([])
  const [ loading, setLoading ] = useState(false)
  const [ showConfirmDeleteModal, setShowConfirmDeleteModal ] = useState<string | undefined>()
  const [ showAddUserModal, setShowAddUserModal ] = useState(false)


  useEffect(() => {

    getCustomers()

  }, [])

  const getCustomers = async () =>
  {
    try
    {
      setLoading(true)
      const users = await new UserApi().getUsers()
      if(users) setUsers(users)
      setLoading(false)
    }
    catch(error)
    {
      setLoading(false)
    }
  }

  const deleteUser = async () =>
  {
    try
    {
      if(!showConfirmDeleteModal) return
      const user = await new UserApi().deleteUser(showConfirmDeleteModal)
      if(user) setUsers(users.filter(u => u._id !== user._id))
      setShowConfirmDeleteModal("")
    }
    catch(error)
    {
      // Error
    }
  }
  
  
  return(<div >

    { loading ? <LoadingPlaceholder /> : 
    <CustomersTable users={users} onDeleteUser={setShowConfirmDeleteModal} />
      }
    { showAddUserModal && <AddCustomerModal open={showAddUserModal} handleClose={() => setShowAddUserModal(false)} onAddSuccess={getCustomers}/>}
    { showConfirmDeleteModal && <ConfirmModal open onConfirm={deleteUser} title="Confirm Delete User" description="Are you sure you want to delete this user?" handleClose={() => setShowConfirmDeleteModal("")}/>}

    <Fab style={styles.fab} onClick={() => setShowAddUserModal(true)}>
        <AddIcon />
    </Fab>
    

  </div>)
}

const styles = {
  container: {

  },
  fab: {
    position: "fixed" as "fixed",
    bottom: 0,
    right: 0,
    top: "auto",
    left: "auto",
    margin: 32,
  }
}