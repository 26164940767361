import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography"


export const PrivacyPolicy = () =>
{
  return(
    <Card style={styles.container}>


             <Typography sx={{mb: 2 }} variant="h2" textAlign='center'> Select Glass App - Privacy Policy</Typography>

             <Typography variant="h6">Effective Date: 25/01/2024</Typography>

             <Typography sx={{mb: 3 }} variant="body1"> Select Glass ("Company," "we," "us," or "our") is committed to protecting the privacy of users of the Select Glass mobile application ("App"). This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information. By accessing or using the App, you consent to the practices described in this Privacy Policy.</Typography>

             <Typography variant="h5">1. Information We Collect</Typography>

             <Typography variant="body1" sx={{mb: 3 }}>

              We may collect various types of information from users, including:

              Personal Information: This may include your name, contact information, billing address, and other identifiable information required for the use of the App.

              Transactional Information: We collect information related to your transactions within the App, including invoices and payment details.

              Device Information: We may collect information about the device you use to access the App, such as device type, operating system, and unique device identifiers.

              Usage Information: We collect information about how you use the App, such as the features you interact with, the pages you visit, and the duration of your sessions.
              </Typography>

              <Typography variant="h5">2. How We Use Your Information</Typography>

              <Typography variant="body1" sx={{mb: 3 }}>
              

              We use the information collected for various purposes, including:

              To provide and maintain the App's functionality.
              To process transactions, send invoices, and facilitate payments.
              To communicate with you, including responding to inquiries and providing customer support.
              To personalize your experience and improve the App's features and content.
              To analyze usage patterns and optimize the App's performance.
              </Typography>

              <Typography variant="h5">3. Sharing of Information</Typography>

              <Typography variant="body1" sx={{mb: 3 }}>
              

              We may share your information with third parties in the following circumstances:

              With your consent.
              To comply with legal obligations or respond to law enforcement requests.
              To protect our rights, property, or safety, or that of our users or others.
              In connection with a merger, acquisition, or sale of assets.

              </Typography>

              <Typography variant="h5">4. Security</Typography>

              <Typography variant="body1" sx={{mb: 3 }}>

              We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.

              </Typography>

              <Typography variant="h5">5. Your Choices</Typography>

              <Typography variant="body1" sx={{mb: 3 }}>
              

              You can control certain aspects of the information you provide to us. You may update your account information, opt-out of promotional communications, and manage your privacy settings within the App.

              </Typography>

              <Typography variant="h5">6. Updates to Privacy Policy</Typography>
              <Typography variant="body1" sx={{mb: 3 }}>

              We reserve the right to update this Privacy Policy to reflect changes in our practices and services. We will notify you of any material changes by posting the updated policy on our website or within the App.
              </Typography>

              <Typography variant="h5">7. Contact Information</Typography>

              <Typography variant="body1" sx={{mb: 3 }}>
              

              For any questions or concerns regarding this Privacy Policy or the Select Glass App, please contact us at [insert contact email].

              By using the Select Glass App, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.
              </Typography>

              <Typography variant="h5">8. Delete your account</Typography>

              <Typography variant="body1" sx={{mb: 3 }}>


              To delete your account from Select Glass. Go into the app, then select the account tab. Tap the delete my account button. It will ask you to confirm. Tap confirm and then it will delete your account and bookings. You will then be logged out.
              </Typography>
    </Card>
  )
}

const styles = {
  container: {
    margin: 16,
    padding: 24
  }
}