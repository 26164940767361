import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Product } from '../../interface/Product';
import moment from 'moment'
import { ImagePreview } from '../../components/ImagePreview';
import Avatar from '@mui/material/Avatar';
import { getInitials } from '../../util/getInitials';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useSearchFilter } from '../../hooks/useSearchFilter';

interface ProductsTableProps
{
  products: Product[] | undefined
  onProductClick: (product: Product) => void
  title: string
}

export const ProductsTable = ({ products, onProductClick, title }: ProductsTableProps) => {

  const [ query, setQuery ] = useState()
  const filtered = useSearchFilter(products, query)

  return (
    <>
    <Typography color="textSecondary" fontWeight="800" fontSize={24}>{title}</Typography>
    <TextField value={query} style={styles.search} fullWidth placeholder={`Search ${title}`} onChange={(e: any) => setQuery(e.target.value)}/>
    <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell></TableCell>
            <TableCell align="left" style={{ textWrap: "nowrap"}}>Products and Services</TableCell>
            <TableCell align="right">Description</TableCell>
            <TableCell align="right">Type</TableCell>

            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Images</TableCell>
            <TableCell align="right">Created</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {filtered?.map((row) => (
            <TableRow
              onClick={() => onProductClick(row)}
              hover
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              

               <TableCell component="th" scope="row" >
               <Avatar style={styles.avatar}>{getInitials(row.title)}</Avatar>
               </TableCell>
              <TableCell component="th" scope="row" >{row.title}</TableCell>
              <TableCell align="right">{row.description}</TableCell>
              <TableCell align="right">{row.type}</TableCell>

              <TableCell align="right">${row.price.toFixed(2)}</TableCell>
              <TableCell align="right"><ImagePreview images={row.images}/></TableCell>
              <TableCell align="right" >{moment(row.createdAt).fromNow()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>

  );
}

const styles = {
  firstItem: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    flexDirection: 'row' as 'row',
  },
  avatar: {
    marginRight: 6,
    backgroundColor: "#7986CB"
  },
  search: {
    marginBottom: 12,
    marginTop: 6,
  }
}