import { Invoice } from "../interface/Invoice";
import { HttpService } from "./HttpService";


export class InvoiceApi extends HttpService
{
  getInvoices = async () =>
  {
    const response = await this.request<Invoice[]>("GET", "invoices")
    return response?.data
  }

  updateInvoice = async (invoice: Partial<Invoice>) =>
  {
    const response = await this.request<Invoice>("PATCH", `invoices?invoiceId=${invoice._id}`, invoice)
    return response?.data
  }

  createInvoice = async (invoice: Invoice) =>
  {
    const response = await this.request<Invoice>("POST", `invoices`, invoice)
    return response?.data
  }

  deleteInvoice = async (invoiceId: string) =>
  {
    const response = await this.request<Invoice>("DELETE", `invoices?invoiceId=${invoiceId}`)
    return response?.data
  }
}