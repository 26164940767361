import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography"

import { Message } from "../../interface/Chat"
import moment from "moment"
import Avatar from "@mui/material/Avatar"
import { getInitials } from "../../util/getInitials"
import { getImagePath } from "../../util/getImagePath"
import { useState } from "react"
import { ImageViewModal } from "../modal/ImageViewModal"


interface BubbleProps
{
  message: Message
}

export const Bubble = ({ message } : BubbleProps) =>
{
  const [ showImageModal, setShowImageModal ] = useState("")

  const getStyles = () =>
  {
    return {
      float: message.fromUser === "Select Glass" ? "right" : "left" as any,
      backgroundColor:  message.fromUser === "Select Glass" ? "#64B5F6" : "#9575CD",
    }
  }

  const getAvatar = () =>
  {
    if(message.fromUser === "Select Glass") return "#B3E5FC"
    return "#9FA8DA"

  }

  return(
    <Card style={{ ...styles.container, ...getStyles() }}>

      <div style={styles.header}>
        <Avatar style={{ backgroundColor: getAvatar(), marginRight: 6 }}>{getInitials(message.fromUser)}</Avatar>
        <Typography fontWeight="800">from: {message.fromUser}</Typography>
      </div>

        <Typography variant="body2" sx={{ mt: 1 }}>{message.text}</Typography>

        { message.images && message.images.length > 0 && <div style={styles.imagesRow}>
          
          { message.images.map((image, key) =>  <img onClick={() => setShowImageModal(image)} style={styles.imageItem} key={key} src={getImagePath(image)} alt=""/> )}

          </div>}
        

        <Typography color="textSecondary" variant="caption" style={styles.timestamp} textAlign="end">{moment(message.timestamp).fromNow()}</Typography>

        { showImageModal && <ImageViewModal open={!!showImageModal} handleClose={() => setShowImageModal("")} imageUrl={showImageModal} /> }

    </Card>
  )
}
const styles = {
  timestamp: {
    position: "absolute" as 'absolute',
    bottom: 0,
    right: 0,
    margin: 6,
  },
  imageItem: {
    borderRadius: 12,
    margin: 12,
    width: 100,
    height: 100
  },
  imagesRow: {

  },
  header: {
    display: 'flex' as 'flex',
    flex: 1,
    alignItems: 'center' as 'center'
  },
  container: {
    position: "relative" as 'relative',
    borderRadius: 12,
    width: 500,
    margin: 12,
    padding: 16,
  }
}