import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import DeleteIcon from "@mui/icons-material/Delete"
import CloseIcon from "@mui/icons-material/Close"

import { Quote } from "../../../interface/Quote"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import { useState } from "react"
import { ImageViewModal } from "../../../components/modal/ImageViewModal"
import { getImagePath } from "../../../util/getImagePath"
import moment from "moment"
import { QuoteApi } from "../../../api/QuoteApi"
import { ConfirmModal } from "../../../components/ConfirmModal"

interface QuoteDetailsModalProps
{
  open: boolean
  onClose: () => void
  quote: Quote
}

export const QuoteDetailsModal = ({ open, onClose, quote } : QuoteDetailsModalProps) =>
{
  const [ showImageModal, setShowImageModal ] = useState("")
  const [ showConfirmDelete, setShowConfirmDelete ] = useState(false)

  const handleDelete = async () => {
    try
    {
      if(!quote._id) return
      const response = await new QuoteApi().delete(quote._id)
      if(response?._id) onClose()
    }
    catch(error)
    {
      // show error
    }
  }

  
  return (<Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">

    <DialogTitle>Quote Details for - {quote.customerEmail} - Requested: {moment(quote.createdAt).format("ddd, lll")} ({moment(quote.createdAt).fromNow()})</DialogTitle>

    <DialogContent>

      <Card style={styles.card}>
        <CardHeader title="Customer Images" subheader={`Click to enlarge`} />
        { quote.images.map((quoteImage, key) => <img onClick={() => setShowImageModal(quoteImage)} style={styles.image} key={key} src={getImagePath(quoteImage)} alt={quoteImage}/>)}
      </Card>

      <Card style={styles.card}>
        <CardHeader title="Product Details" subheader={quote.productName} />
      </Card>

      <Card style={styles.card}>
        <CardHeader title="Dimensions" subheader={`Height: ${quote.height}mm | Width: ${quote.width}mm`} />
      </Card>

      { quote.quoteItems?.map((quoteItem, key) => {
        return(
          <Card key={key} style={styles.card}>
            <CardHeader title={quoteItem.title} subheader={quoteItem.type} />
        </Card>
        )
      })}

    </DialogContent>

    <DialogActions>
      <Button startIcon={<DeleteIcon />} onClick={() => setShowConfirmDelete(true)}>Remove this quote</Button>
      <Button startIcon={<CloseIcon />} onClick={onClose}>Close</Button>
    </DialogActions>

    { showImageModal && <ImageViewModal open={!!showImageModal} handleClose={() => setShowImageModal("")} imageUrl={showImageModal} /> }

    { showConfirmDelete && <ConfirmModal handleClose={() => setShowConfirmDelete(false)} open={showConfirmDelete} onConfirm={handleDelete} description="Do you want to remove this customer quote?" title="Remove Quote" /> }

  </Dialog>)
}

const styles = {
  card: {
    margin: 6,
  },
  image: {
    width: 100,
    height: 100,
    borderRadius: 12,
    margin: 12,
  }
}