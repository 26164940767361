import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { getImagePath } from "../../util/getImagePath"

interface ImageViewModalProps
{
  open: boolean
  handleClose: () => void
  imageUrl: string
}

export const ImageViewModal = ({ open, handleClose, imageUrl }: ImageViewModalProps) =>
{



  return(<Dialog
    fullWidth
    maxWidth="xl"
    open={open}
    onClose={handleClose}>


    <img style={styles.image} alt="" src={getImagePath(imageUrl)} />


    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
    </DialogActions>
    
  </Dialog>)
}

const styles = {
  image: {
    borderRadius: 12,
    objectFit: "cover" as 'cover',
    height: "80vh"
  }
}