import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import { useState } from "react"
import SaveIcon from '@mui/icons-material/SaveOutlined'
import { UserApi } from "../../api/UserApi"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../interface/ApplicationState"
import { setUserProfile } from "../../redux/creators/userCreators"
import Snackbar from "@mui/material/Snackbar"
import { getInitials } from "../../util/getInitials"
import Avatar from "@mui/material/Avatar"

interface ProfileModalProps
{
  open: boolean
  handleClose: () => void
}

export const ProfileModal = ({ open, handleClose }: ProfileModalProps) =>
{

  const email = useSelector((state: ApplicationState) => state.user.email)
  const name = useSelector((state: ApplicationState) => state.user.name)

  const isSettingProfile = useSelector((state: ApplicationState) => state.user.isSettingProfile)
  const [ localUser, setLocalUser ] = useState(email)
  const [ localName, setLocalName ] = useState(name)

  const [ saveSnack, setSaveSnack ] = useState("")

  const dispatch = useDispatch()

  const updateProfile = async () =>
  {
    dispatch(setUserProfile({ email: localUser, name: localName }))
    setSaveSnack("Updated Profile")
  }


  return(<Dialog
    fullWidth
    maxWidth="sm"
    open={open}
    onClose={handleClose}>

    <DialogTitle>Your Profile</DialogTitle>

    <DialogContent>

      <Grid container flexDirection='row' alignItems='center' spacing={2}>

        {/* Avatar */}
        <Grid item xs={3}>
          <Avatar style={styles.avatar}>{getInitials(email)}</Avatar>
        </Grid>

      {/* Inputs */}
      <Grid item container flexDirection="column" spacing={2} xs={9}> 

      <Grid item>
        <TextField autoFocus placeholder="Email" fullWidth value={localUser} onChange={(e: any) => setLocalUser(e.target.value)}/>
      </Grid>

      <Grid item>
        <TextField autoFocus placeholder="Name" fullWidth value={localName} onChange={(e: any) => setLocalName(e.target.value)}/>
      </Grid>  

    </Grid>


      </Grid>

    </DialogContent>

    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
      <Button variant='contained' disabled={isSettingProfile} startIcon={isSettingProfile ? <CircularProgress size={16} /> : <SaveIcon />} onClick={updateProfile}>{ isSettingProfile ? "Updating" : "Update"}</Button>
    </DialogActions>

    <Snackbar open={!!saveSnack} message={saveSnack} color="success" onClose={() => setSaveSnack("")} autoHideDuration={5000} />

    
  </Dialog>)
}

const styles = {
  avatar: {
    width: 120,
    height: 120,
    fontSize: 50,
    fontWeight: "700",
    backgroundColor: "#9575CD"
  }
}