import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import { useState } from "react"
import SaveIcon from '@mui/icons-material/Send'
import Snackbar from "@mui/material/Snackbar"
import { ResetApi } from "../../api/ResetApi"
import VerificationInput from "react-verification-input";

interface ResetPasswordModalProps
{
  open: boolean
  handleClose: () => void
}

export const ResetPasswordModal = ({ open, handleClose }: ResetPasswordModalProps) =>
{
  const [ email, setEmail ] = useState("")
  const [ mfa, setMfa ] = useState<number | undefined>()
  const [ password, setPassword ] = useState("")
  const [ confirmPassword, setConfirmPassword ] = useState("")
  const [ loading, setLoading ] = useState(false)
  const [ hasRequestedReset, setHasRequestedReset ] = useState(false)


  const [ snackMessage, setSnackMessage ] = useState("")

  const requestReset = async () =>
  {
    try
    {
      setLoading(true)
      const response = await new ResetApi().requestReset(email)
      if(response?.result) setSnackMessage(response.result)
      setLoading(false)
      setHasRequestedReset(true)
    }
    catch(error)
    {
      setLoading(false)
      const errorCast = error as any
      setSnackMessage(errorCast?.message?.response?.data?.error || "Looks like something went wrong")
    }
  }

  const changePassword = async () =>
  {
    try
    {
      setLoading(true)
      if(!mfa) return
      const response = await new ResetApi().changePassword(email, mfa, password, confirmPassword)
      if(response?.result) setSnackMessage(response.result)
      setLoading(false)
      setTimeout(() => handleClose(), 5000)
    }
    catch(error)
    {
      setLoading(false)
      const errorCast = error as any
      setSnackMessage(errorCast?.message?.response?.data?.error || "Looks like something went wrong")
    }
  }


  return(<Dialog
    fullWidth
    maxWidth="sm"
    open={open}
    onClose={handleClose}>

    <DialogTitle>Reset Password</DialogTitle>

    <DialogContent>

      <Grid container spacing={2}>

      {/* Inputs */}

      { !hasRequestedReset && 
      <Grid item xs={12}>
          <TextField autoFocus placeholder="Email" fullWidth value={email} onChange={(e: any) => setEmail(e.target.value)}/>
      </Grid>
      }

      { hasRequestedReset && 
      <>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', height: 100, alignItems: 'center' }}>
        <VerificationInput autoFocus onChange={(e) => setMfa(Number(e))} />
        </div>

      <Grid item xs={12}>
        <TextField type="password" placeholder="new Password" fullWidth value={password} onChange={(e: any) => setPassword(e.target.value)}/>
      </Grid>  

      <Grid item xs={12}>
        <TextField type="password" placeholder="Confirm New Password" fullWidth value={confirmPassword} onChange={(e: any) => setConfirmPassword(e.target.value)}/>
      </Grid>  
      </>
      }

    </Grid>

    </DialogContent>

    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
      { hasRequestedReset && <Button variant='contained' disabled={loading || !password || !confirmPassword || !mfa} startIcon={loading ? <CircularProgress size={16} /> : <SaveIcon />} onClick={changePassword}>{ loading ? "Changing Password" : "Change Password"}</Button> }
      { !hasRequestedReset && <Button variant='contained' disabled={loading || !email} startIcon={loading ? <CircularProgress size={16} /> : <SaveIcon />} onClick={requestReset}>{ loading ? "Requesting" : "Request"}</Button> }
    </DialogActions>

    <Snackbar open={!!snackMessage} message={snackMessage} color="success" onClose={() => setSnackMessage("")} autoHideDuration={5000} />

    
  </Dialog>)
}

