import { Chat } from "../../../interface/Chat";


export const getUnseenCount = (messages: Chat[]) =>
{
  let total = 0;

  for(const message of messages)
  {
    total = total + message.totalUnseenByAdmin 
  }

  return total
}
