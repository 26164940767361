import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { User } from "../../interface/User";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";


interface RecentUsersProps
{
  customers: User[] | undefined
}

export const RecentUsers = ({ customers }: RecentUsersProps) =>
{

  return(
    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ mt: 2 }}>

    <Typography fontWeight="800" color="textSecondary" fontSize={18}>Recent Customers</Typography>

  <TableContainer component={Paper} sx={{ height: 600, overflow: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableHead}>Type</TableCell>
              <TableCell style={styles.tableHead}>Email</TableCell>
              <TableCell align="right" style={styles.tableHead}>Company Name</TableCell>
              <TableCell align="right" style={styles.tableHead}>Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers?.map((row) => (
              <TableRow
                hover
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                   <TableCell component="th" scope="row">
                <Avatar style={{ backgroundColor: row.isAdmin ? "#26A69A" : "#BA68C8" }} >{row.email[0].toUpperCase() + row.email[1].toUpperCase()}</Avatar>
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.email}
                </TableCell>
                <TableCell align="right">{row.name || "-"}</TableCell>
                <TableCell align="right">{moment(row.createdAt).fromNow()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    
    </Grid>
  )
}

const styles = {
  tableHead: {
    fontWeight: "700",
    color: "grey"
  }
}