import { put, takeLatest } from "redux-saga/effects";
import { UserActions } from "../actions/UserActions";
import { LoginApi } from "../../api/LoginApi";
import { getUserProfileError, getUserProfileSuccess, loginError, loginSuccess, setUserProfileError, setUserProfileSuccess } from "../creators/userCreators";
import { variables } from "../../const";
import { UserApi } from "../../api/UserApi";


function* doLogin(action: { data: { email: string, password: string}}): any
{
  try
  {
    const response = yield new LoginApi().login(action.data.email, action.data.password)
    yield localStorage.setItem(variables.KEY_TOKEN, response.token)
    yield put(loginSuccess(response))
  }
  catch(error)
  {
    console.log(error)
    const errorCast = error as any
    yield put(loginError(errorCast?.message?.response?.data?.error || "Looks like something went wrong"))

  }
}

function* getUserProfile(): any
{
  try
  {
    const user = yield new UserApi().getProfile()
    yield put(getUserProfileSuccess(user))
  }
  catch(error)
  {
    console.log(error)
    const errorCast = error as any
    yield put(getUserProfileError(errorCast?.message?.response?.data?.error || "Looks like something went wrong"))

  }
}

function* setProfile(action: any): any
{
  try
  {
    const user = yield new UserApi().updateUser(action.data.user)
    yield put(setUserProfileSuccess(user))
  }
  catch(error)
  {
    const errorCast = error as any
    yield put(setUserProfileError(errorCast?.message?.response?.data?.error || "Looks like something went wrong"))

  }
}

export function* userSaga()
{ 
  yield takeLatest<any>(UserActions.LOGIN_START, doLogin)
  yield takeLatest<any>(UserActions.GET_USER_PROFILE_START, getUserProfile)
  yield takeLatest<any>(UserActions.SET_USER_PROFILE_START, setProfile)
  yield takeLatest<any>(UserActions.LOGIN_SUCCESS, getUserProfile)
}