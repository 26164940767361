import { Quote } from "../interface/Quote"


export const useQuoteSearchFilter = (quotes?: Quote[], query?: string) =>
{
  try
  {
    if(!query) return quotes
    if(!quotes) return []
    const filtered = quotes.filter(quote => {
      if(quote?.customerName?.toLowerCase().includes(query.toLowerCase())) return true
      if(quote?.customerEmail?.toLowerCase().includes(query.toLowerCase())) return true
      for(const item of quote.quoteItems) if(item.title.toLowerCase().includes(query.toLowerCase())) return true
      return false
    })
    return filtered
  }
  catch(error)
  {
    return quotes
  }
}