import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../interface/ApplicationState"
import ChatIcon from "@mui/icons-material/SupportAgent"
import { useState } from "react"
import { Chat } from "../../interface/Chat"
import { ConfirmModal } from "../ConfirmModal"
import { deleteConversationStart, setCurrentChat } from "../../redux/creators/chatCreators"
import { PlaceholderSmall } from "../Placeholder"
import { MessageList } from "./MessageList"
import { ConversationView } from "./ConversationView"
import IconButton from "@mui/material/IconButton"

import ArrowBackIos from "@mui/icons-material/ArrowBackIos"
import CloseIcon from "@mui/icons-material/Close"
import Grid from "@mui/material/Grid"


interface ChatModalProps
{
  open: boolean
  handleClose: () => void
}

export const ChatModal = ({ open, handleClose }: ChatModalProps) =>
{
  const [ showConfirmDeleteModal, setShowConfirmDeleteModal ] = useState<Chat | undefined>()
  const messages = useSelector((state: ApplicationState) => state.chat.messages)
  const isGettingMessage = useSelector((state: ApplicationState) => state.chat.isGettingMessage)
  const currentChatId = useSelector((state: ApplicationState) => state.chat.currentChatId)

  const dispatch = useDispatch()

  const handleRemove = () =>
  {
    if(!showConfirmDeleteModal?._id) return
    dispatch(deleteConversationStart(showConfirmDeleteModal?._id))
    setShowConfirmDeleteModal(undefined)
  }

  return(
    <Dialog 
      PaperProps={{ style: { position: 'fixed', bottom: 0, left: 0 } }}
      open={open} 
      onClose={handleClose} 
      fullWidth 
  
      maxWidth="md">

      <Grid display="flex" alignItems='center'>
        <Grid item>
          { currentChatId && <IconButton sx={{ ml: 2 }} onClick={() => dispatch(setCurrentChat(""))}><ArrowBackIos /></IconButton> }
        </Grid>
        <Grid item>
          <DialogTitle>{currentChatId ? "Conversation" : "Chat List" }</DialogTitle>
        </Grid>

        <Grid flex={1}/>

        <Grid item>
          <IconButton sx={{ mr: 2 }} onClick={handleClose}><CloseIcon /></IconButton>
        </Grid>

      </Grid>


      <DialogContent style={styles.content}>

      { !isGettingMessage && messages.length === 0 && <PlaceholderSmall icon={<ChatIcon style={styles.icon} />} text="No messages from people at the moment"/>}

      { !currentChatId && <MessageList messages={messages} setShowConfirmDeleteModal={setShowConfirmDeleteModal}/>}

      { currentChatId && <ConversationView /> }
        
      </DialogContent>

      { showConfirmDeleteModal && <ConfirmModal open description="Delete this conversation?" title="Remove Conversation" handleClose={() => setShowConfirmDeleteModal(undefined)} onConfirm={handleRemove}/>}

    </Dialog>
  )
}

const styles = {
  icon: {
    width: 64,
    height: 64,
  },
  content: {
    minHeight: 500,
  },
}