import CircularProgress from "@mui/material/CircularProgress"


export const LoadingPlaceholder = () =>
{
  return(<div style={styles.container}>
    <CircularProgress size={64} />
  </div>)
}

const styles = {
  container: {
    height: "80vh",
    flex: 1,
    display: 'flex' as 'flex',
    justifyContent: "center" as 'center',
    alignItems: 'center' as 'center',
    flexDirection: "column" as 'column'
  }
}