import { useEffect, useState } from "react"
import { InvoicesTable } from "./invoices/InvoicesTable"
import { Invoice } from "../interface/Invoice"
import { InvoiceApi } from "../api/InvoiceApi"
import Snackbar from "@mui/material/Snackbar"
import Fab from "@mui/material/Fab"
import AddIcon from '@mui/icons-material/Add'
import ReceiptLong from '@mui/icons-material/ReceiptLong'

import { CreateInvoiceModal } from "./invoices/CreateInvoiceModal"
import { EditInvoiceModal } from "./invoices/EditInvoiceModal"
import { LoadingPlaceholder } from "../components/LoadingPlaceholder"
import { Placeholder } from "../components/Placeholder"


export const Invoices = () =>
{
  const [ invoices, setInvoices ] = useState<Invoice[] | undefined>([])
  const [ loading, setLoading ] = useState(false)
  const [ showSnackRemoveInvoice, setRemoveInvoice ] = useState(false)

  const [ showAddInvoiceModal, setShowAddInvoiceModal ] = useState(false)
  const [ showEditInvoiceModal, setShowEditInvoiceModal ] = useState<Invoice | undefined>()

  useEffect(() => {
    getInvoices()
  }, [])

  const getInvoices = async () =>
  {
    try
    {
      setLoading(true)
      const invoices = await new InvoiceApi().getInvoices()
      setInvoices(invoices)
      setLoading(false)
    }
    catch(error)
    {
      setLoading(false)
    }

  }

  const handleAdd = (invoice: Invoice) =>
  {
    setInvoices([ invoice, ...invoices || [] ])
    setShowAddInvoiceModal(false)
  }

  const handleUpdate = (invoice: Invoice) =>
  {
    console.log("invoice", invoice)
    const updated = invoices?.map(i => {
      if(i._id === invoice._id) return invoice;
      return i
    })

    setInvoices(updated)
  }

  const handleDelete = (deletedInvoice: Invoice) =>
  {
    setInvoices(invoices?.filter(i => i._id !== deletedInvoice._id))
    setShowEditInvoiceModal(undefined)
    setRemoveInvoice(true)
  }

  return(<div >

    { loading && <LoadingPlaceholder />}
    { !loading && (invoices?.length === 0 || !invoices) && <Placeholder text="No Invoices Created Yet" icon={<ReceiptLong style={styles.icon} fontSize="large" htmlColor="grey"/>}  />}
    { invoices && invoices.length > 0 && <InvoicesTable invoices={invoices} onInvoiceClick={setShowEditInvoiceModal}/> }

    <Snackbar open={showSnackRemoveInvoice} message="Removed invoice OK" color="success" onClose={() => setRemoveInvoice(false)} autoHideDuration={5000} />

    { showAddInvoiceModal && <CreateInvoiceModal open={showAddInvoiceModal} handleClose={() => setShowAddInvoiceModal(false)} onAddSuccess={(invoice) => handleAdd(invoice)}/> }

    { showEditInvoiceModal && <EditInvoiceModal 
      invoice={showEditInvoiceModal} 
      open={!!showEditInvoiceModal} 
      handleClose={() => setShowEditInvoiceModal(undefined)} 
      onUpdateSuccess={handleUpdate} 
      onDeleteSuccess={handleDelete}
      
      /> }

    <Fab style={styles.fab} onClick={() => setShowAddInvoiceModal(true)}>
        <AddIcon />
    </Fab>
    
  </div>)
}

const styles = {
  container: {

  },
  icon: {
    width: 128,
    height: 128,
  },
  fab: {
    position: "fixed" as "fixed",
    bottom: 0,
    right: 0,
    top: "auto",
    left: "auto",
    margin: 32,
  }
}