import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IBooking } from "../../interface/IBooking";
import moment from "moment";
import Button from "@mui/material/Button";
import CheckIcon from '@mui/icons-material/Check'
import PendingIcon from '@mui/icons-material/Pending'
import { useState } from "react";
import { BookingModal } from "../bookings/BookingModal";

interface RecentBookingsProps
{
  bookings: IBooking[] | undefined
  getDashboard: (showLoad: boolean) => void
}

export const RecentBookings = ({ bookings, getDashboard }: RecentBookingsProps) =>
{
  const [ showBookingModal, setShowBookingModal ] = useState<IBooking | undefined>()
 
  return(
    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ mt: 2 }}>

    <Typography fontWeight="800" color="textSecondary" fontSize={18}>Recent Bookings</Typography>

    <TableContainer component={Paper} sx={{ height: 600, overflow: "auto" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={styles.tableHead} >Customer</TableCell>
            <TableCell style={styles.tableHead} align="right">Booking Time</TableCell>
            <TableCell style={styles.tableHead} align="right">Requested</TableCell>
            <TableCell style={styles.tableHead} align="right">Confirmed Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings?.map((row) => (
            <TableRow
              hover
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{row.userEmail || "-"}</TableCell>

              <TableCell align="right">{moment(row.startDate).format("h:mmA DD/MM/YYYY") } ({moment(row.startDate).fromNow()})</TableCell>
              <TableCell align="right">{moment(row.createdAt).fromNow()}</TableCell>
              <TableCell align="right">{row.isConfirmed ? 

              <Button startIcon={<CheckIcon />} color="success" variant="contained" sx={{ width: 150 }} onClick={() => setShowBookingModal(row)}>Confirmed</Button>: 

              <Button startIcon={<PendingIcon />} color="warning" variant="outlined" sx={{ width: 150 } } onClick={() => setShowBookingModal(row)}>Pending</Button>}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    { showBookingModal && <BookingModal open={!!showBookingModal} booking={showBookingModal} handleClose={() => setShowBookingModal(undefined)} getDashboard={getDashboard} /> }
   
    </Grid>
  )
}



const styles = {
  tableHead: {
    fontWeight: "700",
    color: "grey"
  }
}