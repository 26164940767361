import { LoginResponse } from "../../interface/LoginResponse"
import { User } from "../../interface/User"
import { UserActions } from "../actions/UserActions"


export const performLogin = (email: string, password: string) =>
{
  return {
    type: UserActions.LOGIN_START,
    data: { email, password }
  }
}

export const loginSuccess = (loginResponse: LoginResponse) =>
{
  return {
    type: UserActions.LOGIN_SUCCESS,
    data: { 
          email: loginResponse.email, 
          isDarkModeOn: loginResponse.isDarkModeOn,
          isAuthed: loginResponse.token ? true: false
        }
  }
}

export const loginError = (error: string) =>
{
  return {
    type: UserActions.LOGIN_ERROR,
    error,
  }
}

export const authState = (value: boolean) =>
{
  return {
    type: UserActions.AUTH_STATE,
    data: { isAuthed: value }
  }
}

export const clearLoginError = () =>
{
  return {
    type: UserActions.CLEAR_LOGIN_ERROR,
  }
}

export const getUserProfile = () =>
{
  return {
    type: UserActions.GET_USER_PROFILE_START,
  }
}

export const getUserProfileSuccess = (user: User) =>
{
  return {
    type: UserActions.GET_USER_PROFILE_SUCCESS,
    data: { user }
  }
}

export const getUserProfileError = (error: string) =>
{
  return {
    type: UserActions.GET_USER_PROFILE_ERROR,
    error,
  }
}

export const setUserProfile = (user: Partial<User>) =>
{
  return {
    type: UserActions.SET_USER_PROFILE_START,
    data: { user }
  }
}

export const setUserProfileSuccess = (user: User) =>
{
  return {
    type: UserActions.SET_USER_PROFILE_SUCCESS,
    data: { user }
  }
}

export const setUserProfileError = (error: string) =>
{
  return {
    type: UserActions.SET_USER_PROFILE_ERROR,
    error,
  }
}