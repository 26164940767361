import { Product } from "../interface/Product"


export const useSearchFilter = (products?: Product[], query?: string) =>
{
  try
  {
    if(!query) return products
    if(!products) return []
    const filtered = products.filter(product => {
      if(product?.description?.toLowerCase().includes(query.toLowerCase())) return true
      if(product?.title?.toLowerCase().includes(query.toLowerCase())) return true
      return false
    })
    return filtered
  }
  catch(error)
  {
    return products
  }
}