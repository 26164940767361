import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import WindowIcon from '@mui/icons-material/Window';
import Box from "@mui/material/Box";
import { CustomAppBar } from "../components/CustomAppBar";
import MenuItem from "@mui/material/MenuItem";
import CssBaseline from "@mui/material/CssBaseline";
import { amber } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authState, setUserProfile } from "../redux/creators/userCreators";
import { ApplicationState } from "../interface/ApplicationState";
import { ProfileModal } from "../components/modal/ProfileModal";
import { DarkModeSwitch } from "../components/DarkModeSwitch";
import Button from "@mui/material/Button";



export const AppBarSection = () =>
{

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch()
  const [ showProfileModal, setShowProfileModal ] = useState(false)

  const email = useSelector((state: ApplicationState) => state.user.email)
  const isAdmin = useSelector((state: ApplicationState) => state.user.isAdmin)

  const isDarkModeOn = useSelector((state: ApplicationState) => state.user.isDarkModeOn)


  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () =>
  {
    localStorage.clear()
    dispatch(authState(false))
    window.location.href = "/"
  }

  const handleClose = () =>
  {
    setAnchorEl(null);
  }
  
  useEffect(() => {

    handleClose()

  }, [ showProfileModal ])

  return(
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <CustomAppBar position="fixed">
      <Toolbar disableGutters>
      <div style={styles.title} >
      <IconButton
          color="inherit"
          onClick={() => {}}
          edge="start"
          sx={{
            marginRight: 2,
          }}
        >
          <WindowIcon color="secondary" />
        </IconButton>
        <Typography variant="subtitle1" fontSize={22} color="secondary">Select</Typography>
        <Typography variant="subtitle1" fontSize={22} fontWeight="800" color="secondary">Glass</Typography>

      </div>
      <Box sx={{ flex: '1' }} />

      <Button sx={{ mr: 2 }} color="secondary" variant="outlined">{isAdmin ? "Admin" : "User"}</Button>

      <DarkModeSwitch checked={isDarkModeOn} onChange={(e) => dispatch(setUserProfile({ isDarkModeOn: e.target.checked }))}/>
      <Avatar style={styles.avatar} onClick={handleClick}>{email && email[0].toUpperCase()}</Avatar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose} >
          <MenuItem onClick={() => setShowProfileModal(true)}>Profile</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
       
      </Toolbar>


      { showProfileModal && <ProfileModal open={showProfileModal} handleClose={() => setShowProfileModal(false)} /> }


  </CustomAppBar>
  </Box>
  )
}

const styles = {
  container: {
    marginRight: 16,
  },
  icon: {
    marginRight: 6,
  },
  title: {
    marginLeft: 16,
    flexDirection: 'row' as 'row',
    display: 'flex' as 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: amber[500],
    marginRight: 12,
    marginLeft: 12,

  }
}
