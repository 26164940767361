import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'

interface ConfirmModalProps
{
  open: boolean;
  title: string
  description: string
  handleClose: () => void
  onConfirm: () => void
}

export const ConfirmModal = ({ open, title, description, handleClose, onConfirm }: ConfirmModalProps) =>
{
  return(
    <Dialog open={open} onClose={handleClose}>

      <DialogTitle>{title}</DialogTitle>

      <DialogContent>

        <DialogContentText>{description}</DialogContentText>

      </DialogContent>

      <DialogActions>
        <Button startIcon={<CloseIcon />} onClick={handleClose}>Cancel</Button>
        <Button startIcon={<CheckIcon />} onClick={onConfirm}>Confirm</Button>
      </DialogActions>
      
    </Dialog>
  )
}