import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { Quote } from "../interface/Quote"
import { QuoteApi } from "../api/QuoteApi"
import { CustomerQuotesTable } from "./quotes/CustomerQuotes/CustomerQuotesTable"
import { LoadingPlaceholder } from "../components/LoadingPlaceholder"
import { Placeholder } from "../components/Placeholder"
import Shop2Outlined from "@mui/icons-material/Shop2Outlined"
import { QuoteDetailsModal } from "./quotes/CustomerQuotes/QuoteDetailsModal"


export const Quotes = () =>
{

  const [ quotes, setQuotes ] = useState<Quote[]>()
  const [ loading, setLoading ] = useState(false)
  const [ showQuoteModal, setShowQuoteModal ] = useState<Quote | undefined>()

  useEffect(() => {
    getQuotes()
  }, [])

  useEffect(() => {

    if(!showQuoteModal) getQuotes()

  }, [ showQuoteModal])

  const getQuotes = async () => {
    try
    {
      setLoading(true)
      const response = await new QuoteApi().get()
      setQuotes(response!)
      setLoading(false)
    }
    catch(error)
    {
      setLoading(false)
    }
  }

  return(<Grid container direction="column">

      <Grid item>
        <Typography color="textSecondary" fontWeight={700} variant="h6" fontSize={24}>Customer Quote Requests</Typography>
        <Divider/>
      </Grid>
      
      <Grid item>        
      { loading && <LoadingPlaceholder />}
      { !loading && (quotes?.length === 0 || !quotes) && <Placeholder text="No Quotes Yet" icon={<Shop2Outlined style={styles.icon} fontSize="large" htmlColor="grey"/>}  />}
      { quotes && quotes.length > 0 && <CustomerQuotesTable quotes={quotes} onQuoteClick={setShowQuoteModal}  /> }
      </Grid>

      { !!showQuoteModal && <QuoteDetailsModal open={!!showQuoteModal} onClose={() => setShowQuoteModal(undefined)} quote={showQuoteModal}/> }

  </Grid>)
}

const styles = {

  icon: {
    width: 128,
    height: 128,
  },
}