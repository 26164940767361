import Grid from "@mui/material/Grid"
import { InvoiceServiceItem } from "../../interface/Invoice"
import TextField from "@mui/material/TextField"
import Badge from "@mui/material/Badge"
import RemoveIcon from "@mui/icons-material/RemoveCircle"
import IconButton from "@mui/material/IconButton"

interface ServiceRowProps
{
  service: InvoiceServiceItem
  onRemove: (_id?: string, serviceId?: string) => void
  updateServiceRow: (service: InvoiceServiceItem) => void
}

export const ServiceRow = ({ service, onRemove, updateServiceRow }: ServiceRowProps) =>
{

  const onUpdate = (updateService: Partial<InvoiceServiceItem>) =>
  {
    updateServiceRow({ ...service, ...updateService })
  }

  return(
    <Grid container spacing={1} sx={{ mb: 1, mt: 1 }}>

      <Grid item xs={8}>
        <TextField value={service.description} fullWidth placeholder="Service Description" onChange={(e: any) => onUpdate({ description: e.target.value })}>{service.description}</TextField>
      </Grid>

      <Grid item xs={2}>
        <TextField type="number" inputMode="numeric" helperText="hrs" value={service.duration} placeholder="hrs" onChange={(e: any) => onUpdate({ duration: e.target.value })}>{service.description}</TextField>
      </Grid>

      <Grid item xs={2}>
        <Badge badgeContent={<IconButton onClick={() => onRemove(service?._id, service?.localId)}><RemoveIcon htmlColor="grey" /></IconButton>}>
        <TextField type="number" inputMode="numeric" helperText="amount" value={service.amount * service.duration} placeholder="Amount" onChange={(e: any) => onUpdate({ amount: e.target.value })}>${service.amount}</TextField>
        </Badge>

      </Grid>


    </Grid>
  )
}