import { ChatStore } from "../../interface/ChatStore"
import { ChatActions } from "../actions/ChatActions"
import { getUnseenCount } from "./reducerFunctions/getUnseenCount"
import { updateMessages } from "./reducerFunctions/updateMessages"



const initialState: ChatStore = {
  unreadMessageCount: 0,
  messages: [],
  isSendingMessage: false,
  isGettingMessage: false,
  isMarkingRead: false,
  isDeletingConversation: false,
  error: "",
  currentChatId: ""
}

export const chatReducer = (state = initialState, action: any) =>
{
  switch(action.type)
  {
    case ChatActions.SET_CURRENT_CHAT:
      return {
        ...state,
        currentChatId: action.data.conversationId,
      }
    case ChatActions.GET_ALL_MESSAGES_START:
      return {
        ...state,
        isGettingMessage: true
      }
      case ChatActions.GET_ALL_MESSAGES_SUCCESS:
        return {
          ...state,
          isGettingMessage: false,
          messages: action.data.messages,
          unreadMessageCount: getUnseenCount(action.data.messages)
        }
      case ChatActions.GET_ALL_MESSAGES_ERROR:
        return {
          ...state,
          isGettingMessage: false,
          error: action.error,
        }
    case ChatActions.SEND_MESSSAGE_START:
      return {
        ...state,
        isSendingMessage: true,
      }
    case ChatActions.SEND_MESSSAGE_SUCCESS:
      return {
        ...state,
        isSendingMessage: false,
        messages: updateMessages(state.messages, action.data.chat)
      }
    case ChatActions.SEND_MESSSAGE_ERROR:
      return {
        ...state,
        isSendingMessage: false,
        error: action.error,
      }
    case ChatActions.DELETE_CONVERSATION_START:
      return {
        ...state,
        isDeletingConversation: true,
      }
    case ChatActions.DELETE_CONVERSATION_SUCCESS:
      return {
        ...state,
        isDeletingConversation: false,
        messages: state.messages.filter(m => m._id !== action.data.chat._id)
      }
    case ChatActions.DELETE_CONVERSATION_ERROR:
      return {
        ...state,
        isDeletingConversation: false,
        error: action.error,
      }
    case ChatActions.MARK_READ_START:
      return {
        ...state,
        isMarkingRead: true,
      }
    case ChatActions.MARK_READ_SUCCESS:
      return {
        ...state,
        isMarkingRead: false,
        messages: updateMessages(state.messages, action.data.chat),
        unreadMessageCount: getUnseenCount(updateMessages(state.messages, action.data.chat))
      }
    default:
      return state
  }
}