import { DashboardResponse } from "../interface/DashboardResponse";
import { HttpService } from "./HttpService";


export class DashboardApi extends HttpService
{
  getDashboard = async () =>
  {
    const response = await this.request<DashboardResponse>("GET", "dashboard")
    if(response) return response.data
  }
}