import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment'
import { ImagePreview } from '../../components/ImagePreview';
import Typography from '@mui/material/Typography';
import { Purchase } from '../../interface/Purchase';

interface PurchasesTableProps
{
  purchases: Purchase[] | undefined
}

export const PurchasesTable = ({ purchases }: PurchasesTableProps) => {


  return (
    <>
    <Typography color="textSecondary" fontWeight="800" fontSize={24}>Customer Purchases and Orders</Typography>
    <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell align="left">Image</TableCell>
            <TableCell align="left" >Product Purchased</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="right">Price Per Item</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Order Total</TableCell>
            <TableCell align="right">Purchase Date</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {purchases?.map((row) => (
            <TableRow
              hover
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              
               <TableCell align="left"><ImagePreview images={[row.product.images[0]]}/></TableCell>
               <TableCell component="th" scope="row" >{row.product.title}</TableCell>

              <TableCell component="th" scope="row" >{row.product.description}</TableCell>
              <TableCell align="right">${row.product.price.toFixed(2)}</TableCell>
              <TableCell align="right">{row.quantity || 1} </TableCell>
              <TableCell align="right">${(row.product.price * (row.quantity || 1)).toFixed(2)} inc. GST</TableCell>

              <TableCell align="right" >{moment(row.createdAt).fromNow()} </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>

  );
}

const styles = {
  firstItem: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    flexDirection: 'row' as 'row',
  },
  avatar: {
    marginRight: 6,
    backgroundColor: "#7986CB"
  },
  search: {
    marginBottom: 12,
    marginTop: 6,
  }
}