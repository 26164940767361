
import Paper from '@mui/material/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView,
  Appointments,
  DayView,
} from '@devexpress/dx-react-scheduler-material-ui';
import Typography from '@mui/material/Typography';
import { BookingsApi } from '../api/BookingsApi';
import { useEffect, useState } from 'react';
import { IBooking } from '../interface/IBooking';
import moment from 'moment';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import ArrowBack from '@mui/icons-material/ArrowBack';
import ViewTypeIcon from '@mui/icons-material/ViewWeek';

import ArrowForward from '@mui/icons-material/ArrowForward';
import TodayIcon from '@mui/icons-material/Today';



export const Bookings = () =>
{

  const [ bookings, setBookings ] = useState<IBooking[] | undefined>([])
  const [ loading, setLoading ] = useState(false)
  const [ viewType, setViewType ] = useState("week")

  const [ currentDate, setCurrentDate ] = useState(moment().format("YYYY-MM-DD"))

  useEffect(() => {

    getBookings()

  }, [])

  const getBookings = async () =>
  {   
    try
    {
      setLoading(true)
      const bookings = await new BookingsApi().getBookings()
      setBookings(bookings)
      setLoading(false)
    }
    catch(error)
    {
      setLoading(false)
    }

  }


  return(<div >

    <Grid container alignItems='center'>

      <Grid item>
        <Typography variant='h6' color="textSecondary">Bookings for current week</Typography>
      </Grid>
      
      <Grid item flex={1}>
        <Button variant={viewType === "week" ? "contained" : "text"} sx={{ ml: 2 }} startIcon={<ViewTypeIcon />} onClick={() => setViewType("week")}>Week View</Button>
        <Button variant={viewType === "day" ? "contained" : "text"} sx={{ ml: 2 }} startIcon={<ViewTypeIcon />} onClick={() => setViewType("day")}>Day View</Button>

      </Grid>

      <Grid item>
        <Button sx={{ mr: 1}} startIcon={<ArrowBack />} onClick={() => setCurrentDate(moment(currentDate).subtract("7", 'days').format("YYYY-MM-DD"))}>Back</Button>
      </Grid>

      <Grid item>
        <Button startIcon={<TodayIcon />} onClick={() => setCurrentDate(moment().format("YYYY-MM-DD"))}>Today</Button>
      </Grid>

      <Grid item>
        <Button startIcon={<ArrowForward />} onClick={() => setCurrentDate(moment(currentDate).add("7", 'days').format("YYYY-MM-DD"))}>Forward</Button>
      </Grid>


    </Grid>

      <Paper>
          <Scheduler
            data={bookings}
          >
            <ViewState
              currentDate={currentDate}
            />
            { viewType === "week" && <WeekView
              startDayHour={6}
              endDayHour={18}
            /> }

            { viewType === "day" && <DayView
              startDayHour={6}
              endDayHour={18}
            /> }
            <Appointments />
          </Scheduler>
        </Paper>
    
  </div>)
}