import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Save from '@mui/icons-material/Save';
import Delete from '@mui/icons-material/Delete';
import AttachMoney from "@mui/icons-material/AttachMoney"

import { useState } from "react"
import { VisuallyHiddenInput } from "../../../components/VisuallyHiddenInput"
import { getImagePath } from "../../../util/getImagePath"
import { QuoteItem, QuoteItemTypes } from "../../../interface/QuoteItem"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { QuoteItemApi } from "../../../api/QuoteItemApi"

interface EditQuoteItemModalProps
{
  quoteItem: QuoteItem
  open: boolean
  onClose: () => void
  reloadList: () => void
}

export const EditQuoteItemModal = ({ open, onClose, quoteItem, reloadList }: EditQuoteItemModalProps) => {

  const [ title, setTitle ] = useState(quoteItem.title)
  const [ cost, setCost ] = useState(quoteItem.cost)
  const [ image, setImage ] = useState<string>(quoteItem.image)
  const [ type, setType ] = useState<QuoteItemTypes>(quoteItem.type)

  const [ saving, setSaving ] = useState(false)
  const [ deleting, setDeleting ] = useState(false)

  const handleFileInput = (e: any) =>
  {
    const newFile = e.target.files[0]

    const reader = new FileReader();
    reader.onloadend = () => {

      if(reader.result) setImage(reader.result as string)
    }

    reader.readAsDataURL(newFile);
  }

  const handleUpdate = async () => {

    try
    {
      setSaving(true)
      if(!cost || !title || !image || !type) return
      const response = await new QuoteItemApi().update({ type, cost: Number(cost), image: image as string, title, _id: quoteItem._id! })
      setSaving(false)
      if(response?._id)
      {
        reloadList()
        onClose()
      }
    }
    catch(error)
    {
      setSaving(false)
    }

  }

  const handleDelete = async () => {

    try
    {
      setDeleting(true)
      if(!quoteItem._id) return
      const response = await new QuoteItemApi().delete(quoteItem._id)
      setDeleting(false)
      if(response?._id)
      {
        reloadList()
        onClose()
      }
    }
    catch(error)
    {
      setDeleting(false)
    }

  }


  return(
    <Dialog open={open} onClose={onClose} >
      <DialogTitle>Edit quote item: {quoteItem.title}</DialogTitle>

      <DialogContent>


      <Typography color="textSecondary" sx={{ mb: 1 }}>Set the field's category (E.G) Glass Types</Typography>

        <Select fullWidth value={type} onChange={(e: any) => setType(e.target.value)} sx={{ mb: 1 }}>
          { Object.values(QuoteItemTypes).map((item, key) => <MenuItem value={item} key={key}>{item}</MenuItem>) }
        </Select>
        
        <Typography color="textSecondary" sx={{ mb: 1 }}>Description of the field type</Typography>
        <TextField fullWidth placeholder="Description of the field type" value={title} onChange={(e: any) => setTitle(e.target.value)} sx={{ mb: 1 }} />

        <Typography color="textSecondary" sx={{ mb: 1 }}>Cost per square meter (only calculates if product has no fixed price)</Typography>
        <TextField InputProps={{ startAdornment: <AttachMoney />}} type="number" fullWidth placeholder="Cost per m²" value={cost} onChange={(e: any) => setCost(e.target.value)} />

        <Typography color="textSecondary" sx={{ mb: 2 }}>Upload image: max 5MB</Typography>

        { image && <img src={image.startsWith("data:") ? image : getImagePath(image)} alt="preview" style={styles.imagePreview} /> }

        <Button
        fullWidth
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />} >
        Upload new image
        <VisuallyHiddenInput accept="image/*" type="file" onChange={handleFileInput} />
      </Button>


      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button startIcon={<Delete />} disabled={deleting} onClick={handleDelete}>Delete</Button>
        <Button startIcon={<Save />} disabled={saving || !title || !cost} onClick={handleUpdate} variant="contained">Update</Button>
      </DialogActions>

    </Dialog>
  )
}

const styles = {
  imagePreview: {
    objectFit: "cover" as 'cover',
    width: "100%",
    height: 200,
  }
}