import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../interface/ApplicationState"
import { NonAuthRoutes } from "../container/NonAuthRoutes"
import { variables } from "../const"
import { useEffect } from "react"
import moment from "moment"
import { jwtDecode } from 'jwt-decode'
import { authState, getUserProfile } from "../redux/creators/userCreators"
import { AuthedRoutes } from "../container/AuthedRoutes"

export const AuthProvider = () =>
{
  const isAuthed = useSelector((state: ApplicationState) => state.user.isAuthed)
  const dispatch = useDispatch()
  
  const isTokenValid = (token: string) =>
  {
    const decoded = jwtDecode(token)
    return moment().isAfter(moment(decoded.exp))
  }

  useEffect(() =>
  {
    const token = localStorage.getItem(variables.KEY_TOKEN)

    if(token && isTokenValid(token))
    {
      dispatch(authState(true))
      dispatch(getUserProfile())
    }
    else
    {
      dispatch(authState(false))
    }

  }, [])


  if(isAuthed) return <AuthedRoutes />
  return <NonAuthRoutes />
  
}