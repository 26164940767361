import { ThemeProvider } from '@mui/material';
import { Route, Routes } from "react-router-dom";
import { lightTheme } from '../theme/theme';
import { LoginScreen } from '../routes/LoginScreen';
import { PrivacyPolicy } from '../routes/PrivacyPolicy';


export const NonAuthRoutes = () =>
{
  return(
    <ThemeProvider theme={lightTheme}>

    <Routes>
      <Route path="/" element={<LoginScreen />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />

   </Routes>
   
  </ThemeProvider>
 )
}