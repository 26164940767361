import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"


import Grid from "@mui/material/Grid"
import { useState } from "react"

import AddIcon from '@mui/icons-material/Add'
import Typography from "@mui/material/Typography"
import { User } from "../../interface/User"
import { UserApi } from "../../api/UserApi"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import Snackbar from "@mui/material/Snackbar"

interface AddCustomerModalProps
{
  open: boolean
  handleClose: () => void
  onAddSuccess: (user: User) => void
}

export const AddCustomerModal = ({ open, handleClose, onAddSuccess }: AddCustomerModalProps) =>
{

  const [ isAdding, setAdding ] = useState(false)
  const [ email, setEmail ] = useState("")
  const [ name, setName ] = useState("")
  const [ password, setPassword ] = useState("")
  const [ confirmPassword, setConfirmPassword ] = useState("")
  const [ isAdmin, setAdmin ] = useState(false)
  const [ showSnack, setShowSnack ] = useState("")

  const addCustomer = async () =>
  {
    try
    { 
      setAdding(true)
      const user = await new UserApi().createUser({ email, name, password, confirmPassword, isAdmin });
      if(user)
      {
        onAddSuccess(user)
        setShowSnack("Successfully created new user")
      }
      setAdding(false)
    }
    catch(error)
    {
      const errorCast = error as any
      setShowSnack(errorCast?.message?.response?.data?.error || "Looks like something went wrong")
      setAdding(false)
    }
  }

  return(<Dialog
    fullWidth
    maxWidth="md"
    open={open}
    onClose={handleClose}>

    <DialogTitle>Add New User</DialogTitle>

    <DialogContent>

      <Grid container flexDirection="column" spacing={2}> 

      <Grid item>
        <Typography color="textSecondary">Make this user an admin user</Typography>
        <FormControlLabel control={<Switch value={isAdmin} onChange={(e: any) => setAdmin(e.target.checked)} />} label="Admin User" />
      </Grid>

      <Grid item>
        <Typography color="textSecondary">Email</Typography>
        <TextField autoFocus placeholder="Email" fullWidth value={email} onChange={(e: any) => setEmail(e.target.value)}/>
      </Grid>


      <Grid item>
        <Typography color="textSecondary">Name or company</Typography>
        <TextField placeholder="Name or company" fullWidth value={name} onChange={(e: any) => setName(e.target.value)}/>
      </Grid>


      <Grid item>
        <Typography color="textSecondary">Password</Typography>
        <TextField type="password" placeholder="Confirm Password" fullWidth value={password} onChange={(e: any) => setPassword(e.target.value)}/>
      </Grid>


      <Grid item>
        <Typography color="textSecondary">Confirm Password</Typography>
        <TextField type="password" placeholder="Confirm Password" fullWidth value={confirmPassword} onChange={(e: any) => setConfirmPassword(e.target.value)}/>
      </Grid>
      
      </Grid>
      
    </DialogContent>

    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
      <Button variant="contained" disabled={isAdding || !email || !password || !confirmPassword} startIcon={isAdding ? <CircularProgress size={16} /> : <AddIcon />} onClick={addCustomer}>{ isAdding ? "Creating..." : "Create User"}</Button>
    </DialogActions>

    <Snackbar open={!!showSnack} message={showSnack} color="success" onClose={() => setShowSnack("")} autoHideDuration={5000} />

    
  </Dialog>)
}
