import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Save from '@mui/icons-material/Save';

import { useState } from "react"
import { VisuallyHiddenInput } from "../../../components/VisuallyHiddenInput"
import { QuoteItemApi } from "../../../api/QuoteItemApi"
import AttachMoney from "@mui/icons-material/AttachMoney"
import { QuoteItemTypes } from "../../../interface/QuoteItem"

interface AddQuoteItemModalProps
{
  open: boolean
  onClose: () => void
  type: QuoteItemTypes
  reloadList: () => void
}

export const AddQuoteItemModal = ({ open, onClose, type, reloadList }: AddQuoteItemModalProps) => {

  const [ title, setTitle ] = useState("")
  const [ cost, setCost ] = useState()
  const [ image, setImage ] = useState<ArrayBuffer | string>()
  const [ saving, setSaving ] = useState(false)

  const handleFileInput = (e: any) =>
  {
    const newFile = e.target.files[0]

    const reader = new FileReader();
    reader.onloadend = () => {

      if(reader.result) setImage(reader.result)
    }

    reader.readAsDataURL(newFile);
  }

  const handleSave = async () => {

    try
    {
      if(!cost || !title || !image) return
      setSaving(true)
      const response = await new QuoteItemApi().create({ type, cost: Number(cost), image: image as string, title })
      setSaving(false)
      if(response?._id)
      {
        reloadList()
        onClose()
      }
    }
    catch(error)
    {
      setSaving(false)
    }

  }


  return(
    <Dialog open={open} onClose={onClose} >
      <DialogTitle>Add new quote item with field {type}</DialogTitle>

      <DialogContent>


        <TextField fullWidth placeholder="Description of the field (E.G. clear 6.38mm)" value={title} onChange={(e: any) => setTitle(e.target.value)} sx={{ mb: 1 }} />
        <TextField InputProps={{ startAdornment: <AttachMoney />}} type="number" fullWidth placeholder="Cost per m²" value={cost} onChange={(e: any) => setCost(e.target.value)} />

        <Typography color="textSecondary" sx={{ mb: 2 }}>Upload image: max 5MB</Typography>

        { image && <img src={image as string} alt="preview" style={styles.imagePreview} /> }

        <Button
        fullWidth
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />} >
        Upload image preview
        <VisuallyHiddenInput accept="image/*" type="file" onChange={handleFileInput} />
      </Button>


      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button startIcon={<Save />} disabled={saving || !title || !cost || !image} onClick={handleSave} variant="contained">Add</Button>
      </DialogActions>

    </Dialog>
  )
}

const styles = {
  imagePreview: {
    objectFit: "cover" as 'cover',
    width: "100%",
    height: 200,
  }
}