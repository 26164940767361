import Grid from "@mui/material/Grid"
import { QuoteItem, QuoteItemTypes } from "../../interface/QuoteItem"
import { useEffect, useState } from "react"
import Typography from "@mui/material/Typography"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import { QuoteItemApi } from "../../api/QuoteItemApi"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface QuoteItemListProps
{
  type: QuoteItemTypes
  selectedQuoteItems: string[]
  handleChangeQuoteItemType: (id: string, checked: boolean) => void
}

export const QuoteItemList = ({ type, handleChangeQuoteItemType, selectedQuoteItems }: QuoteItemListProps) => {

  const [ items, setItems ] = useState<QuoteItem[]>([])
  useEffect(() => {
    getQuoteItems()
  }, [])

  const getQuoteItems = async () => {
    try
    {
      const response = await new QuoteItemApi().get(type)
      if(response) setItems(response)
    }
    catch(error)
    {

    }
  }

  const isChecked = (id: string) => {
    if(selectedQuoteItems.find(selectedItem => selectedItem === id)) return true
    return false
  }

  return (
    <Grid item xs={12}>
    <Accordion >

      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight={700}>{type}</Typography>
        </AccordionSummary>
      <AccordionDetails>
        <Grid container>
        { items.map((item, key) => <Grid item key={key} xs={12}>
          <FormControlLabel 
              checked={isChecked(item._id!)} 
              label={item.title} 
              control={<Switch onChange={(e, checked) => handleChangeQuoteItemType(item._id!, checked)} />}/>
        </Grid>)}

        </Grid>
      </AccordionDetails>

    </Accordion>
    </Grid>
  )
}