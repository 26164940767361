import * as React from 'react';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import { CustomDrawer } from './CustomDrawer';
import { useSelector } from 'react-redux';
import { DrawerHeader } from './DrawerHeader';
import { NavLink } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard'
import QuoteRequestIcon from '@mui/icons-material/CurrencyExchange'

import ProductsIcon from '@mui/icons-material/Shop2'
import ServicesIcon from '@mui/icons-material/HomeRepairService';
import InvoicesIcon from '@mui/icons-material/ReceiptLong';
import QuotesIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Group';
import BookingsIcon from '@mui/icons-material/CalendarMonth';
import PurchasesIcon from '@mui/icons-material/ShoppingBag';


import { ApplicationState } from '../interface/ApplicationState';


export const DrawerList = () =>
{
  const isDarkModeOn = useSelector((state: ApplicationState) => state.user.isDarkModeOn)


  const styles = {
    link: {
      textDecoration: "none",
      color: "grey"
    },
    selectedItem: {
      backgroundColor: isDarkModeOn ? "#424242" : "#EEEEEE"
    }
  }

  return(
    <CustomDrawer
      open={true}
      variant="permanent" >

    <DrawerHeader>
      <IconButton onClick={() => {}}>
        {<ChevronLeftIcon />}
      </IconButton>
    </DrawerHeader>
    
     <List>
       {/* Dashboard */}
       <NavLink to="/" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>) }
      </NavLink>

      <Divider />

      <NavLink to="/products" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <ProductsIcon />
            </ListItemIcon>
            <ListItemText primary="Products" />
          </ListItemButton>
        </ListItem>) }
      </NavLink>


      <NavLink to="/services" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <ServicesIcon />
            </ListItemIcon>
            <ListItemText primary="Services" />
          </ListItemButton>
        </ListItem>) }
      </NavLink>

      <Divider />

      <NavLink to="/customers" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItemButton>
        </ListItem>) }
      </NavLink>

      <NavLink to="/invoices" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <InvoicesIcon />
            </ListItemIcon>
            <ListItemText primary="Invoices" />
          </ListItemButton>
        </ListItem>) }
      </NavLink>


      <NavLink to="/quotes" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <QuoteRequestIcon />
            </ListItemIcon>
            <ListItemText primary="Quote Requests" />
          </ListItemButton>
        </ListItem>) }
      </NavLink>

      <NavLink to="/purchases" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <PurchasesIcon />
            </ListItemIcon>
            <ListItemText primary="Purchases" />
          </ListItemButton>
        </ListItem>) }
      </NavLink>

      <NavLink to="/bookings" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <BookingsIcon />
            </ListItemIcon>
            <ListItemText primary="Bookings" />
          </ListItemButton>
        </ListItem>) }
      </NavLink>

      <Divider />

      <NavLink to="/quoteConfig" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <QuotesIcon />
            </ListItemIcon>
            <ListItemText primary="Quote Config" />
          </ListItemButton>
        </ListItem>) }
      </NavLink>
    

  </List>
  </CustomDrawer>
   
  )
}
