

import { combineReducers } from "redux"
import { userReducer } from "./reducers/userReducer"
import { chatReducer } from "./reducers/chatReducer"


export const rootReducer = combineReducers({
  user: userReducer,
  chat: chatReducer,
})
