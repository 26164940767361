import Grid from "@mui/material/Grid"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Typography from "@mui/material/Typography"



interface ProductTypeSelectorProps
{
  type: string
  setType: (type: string) => void
}

export const ProductTypeSelector = ({ type, setType }: ProductTypeSelectorProps) => {
  return(
<Grid item>
        <Typography color="textSecondary">Product or Service Type</Typography>
        <Select fullWidth value={type} onChange={(e: any) => setType(e.target.value)}>
          <MenuItem value="windowGlassReplacement">Window Glass Replacement</MenuItem>
          <MenuItem value="showerGlassReplacement">Shower Glass Replacement</MenuItem>
          <MenuItem value="mirrorGlassReplacement">Mirror Replacement</MenuItem>
          <MenuItem value="doorGlassReplacement">Door Glass Replacement</MenuItem>
          <MenuItem value="mirrors">Mirrors</MenuItem>
          <MenuItem value="petDoors">Pet Doors</MenuItem>
          <MenuItem value="glassCutToSize">Glass Cut To Size</MenuItem>
          <MenuItem value="wardrobes">Wardrobes</MenuItem>
          <MenuItem value="tables">Table Tops</MenuItem>
          <MenuItem value="shelves">Glass Shelving</MenuItem>
          <MenuItem value="splashBacks">Splashbacks</MenuItem>
          <MenuItem value="newDoors">New aluminium windows and doors</MenuItem>
        </Select>
      </Grid>
  )
}