import { ThemeProvider, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import { Route, Routes } from "react-router-dom";
import { darkTheme, lightTheme } from '../theme/theme';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../interface/ApplicationState';
import { Dashboard } from '../routes/Dashboard';
import { Products } from '../routes/Products';
import { AppBarSection } from './AppBarSection';
import { DrawerList } from '../components/DrawerList';
import { Services } from '../routes/Services';
import { Invoices } from '../routes/Invoices';
import { QuoteConfig } from '../routes/QuoteConfig';
import { Quotes } from '../routes/Quotes';

import { Users } from '../routes/Users';
import { Bookings } from '../routes/Bookings';
import { ChatWidget } from './ChatWidget';
import { Purchases } from '../routes/Purchases';


export const AuthedRoutes = () =>
{
  const isDarkModeOn = useSelector((state: ApplicationState) => state.user.isDarkModeOn)


  return(
    <ThemeProvider theme={isDarkModeOn ? darkTheme : lightTheme}>

    <Box sx={{ display: 'flex', backgroundColor: isDarkModeOn ? "black" : "#eef2f6", height: "100vh" }}>

        <AppBarSection />
        <Toolbar />
        <DrawerList />
        <ChatWidget />

        <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 4 }} >

        <Toolbar />

        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/products" element={<Products />} />
          <Route path="/services" element={<Services />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/quotes" element={<Quotes />} />
          <Route path="/purchases" element={<Purchases />} />
          <Route path="/quotes" element={<Quotes />} />
          <Route path="/customers" element={<Users />} />
          <Route path="/bookings" element={<Bookings />} />
          <Route path="/quoteConfig" element={<QuoteConfig />} />


      </Routes>

    </Box>

   </Box>

   
  </ThemeProvider>
 )
}