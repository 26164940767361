import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment'
import Avatar from '@mui/material/Avatar';
import { getInitials } from '../../util/getInitials';
import { Invoice } from '../../interface/Invoice';
import Typography from '@mui/material/Typography';
import { getInvoiceTotal } from '../../util/getInvoiceTotal';
import Badge from '@mui/material/Badge';

interface InvoiceTableProps
{
  invoices: Invoice[] | undefined
  onInvoiceClick: (invoice: Invoice) => void
}

export const InvoicesTable = ({ invoices, onInvoiceClick }: InvoiceTableProps) => {

  return (
    <>
    <Typography color="textSecondary" fontWeight="800" fontSize={24}>Invoices</Typography>

    <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell></TableCell>
            <TableCell align="left" style={{ textWrap: "nowrap"}}>InvoiceId or title</TableCell>
            <TableCell align="left" style={{ textWrap: "nowrap"}}>Description</TableCell>

            <TableCell align="right">Recipient Email</TableCell>
            <TableCell align="right">Company</TableCell>

            <TableCell align="right">Invoice Total</TableCell>
            <TableCell align="right">Created</TableCell>
            <TableCell align="center">Payment Status</TableCell>


          </TableRow>
        </TableHead>
        <TableBody>
          {invoices?.map((row) => (
            <TableRow
              onClick={() => onInvoiceClick(row)}
              hover
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              
               <TableCell component="th" scope="row" >
               <Avatar style={styles.avatar}>{getInitials(row.title)}</Avatar>
               </TableCell>
              <TableCell component="th" scope="row" >{row.title}</TableCell>
              <TableCell component="th" scope="row" >{row.description}</TableCell>

              <TableCell align="right">{row.email}</TableCell>
              <TableCell align="right">{row.company || "-"}</TableCell>

              <TableCell align="right">${getInvoiceTotal(row).toFixed(2)} inc. GST</TableCell>
              <TableCell align="right" >{moment(row.createdAt).fromNow()}</TableCell>
              <TableCell align="center" >{row.isPaid ? <Badge color="success" badgeContent={`Paid`} /> : <Badge color="warning" badgeContent={`Pending`} />}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>

  );
}

const styles = {
  firstItem: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    flexDirection: 'row' as 'row',
  },
  avatar: {
    marginRight: 6,
    backgroundColor: "#7986CB"
  }
}