import Grid from "@mui/material/Grid"
import { QuoteItemTypes } from "../interface/QuoteItem"
import { QuoteConfigTypesSection } from "./quotes/QuoteItemConfig/QuoteConfigTypesSection"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import ClearIcon from "@mui/icons-material/Clear"

import { useState } from "react"

export const QuoteConfig = () =>
{
  const [ query, setQuery ] = useState("")


  const getFiltered = () => {
    if(!query) return Object.values(QuoteItemTypes)
    return Object.values(QuoteItemTypes).filter(item => item.toLowerCase().includes(query.toLowerCase()))
  }


  return(<Grid container direction="column" spacing={2}>

    <Grid item>
      <TextField 
        InputProps={{
          startAdornment: <SearchIcon />,
          endAdornment: <IconButton onClick={() => setQuery("")}><ClearIcon /></IconButton>
        }}
        value={query} 
        onChange={(e: any) => setQuery(e.target.value)} 
        placeholder='Search config types' 
        fullWidth />
    </Grid>

  <Grid item container direction="column">
  {
    getFiltered().map((item, key) => {
      return <div key={item} style={{ marginBottom: 12 }} >
        <QuoteConfigTypesSection type={item} isSearching={!!query} />
      </div>
    })
  }
  </Grid> 

  </Grid>)
}