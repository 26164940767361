import LinearProgress from "@mui/material/LinearProgress"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EditQuoteItemModal } from "./EditQuoteItemModal"
import { useEffect, useState } from "react"
import AddIcon from '@mui/icons-material/AddOutlined'
import { QuoteItem, QuoteItemTypes } from "../../../interface/QuoteItem"
import { QuoteItemApi } from "../../../api/QuoteItemApi"
import { AddQuoteItemModal } from "./AddQuoteItemModal"
import { QuoteTypeItem } from "./QuoteTypeItem"
import AccordionActions from "@mui/material/AccordionActions"


interface QuoteConfigTypesSectionProps
{
  type: QuoteItemTypes
  isSearching: boolean
}


export const QuoteConfigTypesSection = ({ type, isSearching }: QuoteConfigTypesSectionProps) => {

  const [ configTypes, setConfigTypes ] = useState<QuoteItem[]>([])
  const [ loading, setLoading ] = useState(false)
  const [ showAddModal, setShowAddModal ] = useState(false)
  const [ showEditModal, setShowEditModal ] = useState<QuoteItem | undefined>()
  const [ expanded, setExpanded ] = useState(isSearching)

  useEffect(() => {

    getConfigTypes()

  }, [])


  useEffect(() => {

    setExpanded(isSearching)

  }, [isSearching])


  const getConfigTypes = async () => {
    try
    {
      setLoading(true)
      const types = await new QuoteItemApi().get(type)
      if(types) setConfigTypes(types)
      setLoading(false)
    }
    catch(error)
    { 
      setLoading(false)
    } 
  }


  return (
    <Accordion
      expanded={expanded}
      onChange={(panel, isExpanded: boolean) => {
        setExpanded(!expanded)
        if(isExpanded) getConfigTypes()
      }}>
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />} >
          <Typography color="textSecondary" fontWeight={700} variant="h6">{type} Quote Config</Typography></AccordionSummary>

        <AccordionDetails>
        <Grid item container spacing={2}>
      <Grid item flex={1}>
        <Typography variant="h6" fontWeight={800}></Typography>
      </Grid>

    </Grid>

    <Grid item container direction="row">
      { configTypes.map((type, key) => <QuoteTypeItem key={key} quoteItem={type} onClick={setShowEditModal}/>) }
    </Grid>

    { loading && <LinearProgress /> }

        </AccordionDetails>

        <AccordionActions>
          <Button style={styles.button} onClick={() => setShowAddModal(true)} startIcon={<AddIcon />} variant="contained">Add New {type}</Button>
        </AccordionActions>


    { showAddModal && <AddQuoteItemModal type={type} open={showAddModal} onClose={() => setShowAddModal(false)} reloadList={getConfigTypes} /> }
    { showEditModal && <EditQuoteItemModal quoteItem={showEditModal} open={!!showEditModal} onClose={() => setShowEditModal(undefined)} reloadList={getConfigTypes} /> }

    </Accordion>
  )
}

const styles = 
{
  button: { 
    minWidth: 300
  }
}