import Grid from "@mui/material/Grid"
import { InvoiceProductItem } from "../../interface/Invoice"
import TextField from "@mui/material/TextField"
import Badge from "@mui/material/Badge"
import RemoveIcon from "@mui/icons-material/RemoveCircle"
import IconButton from "@mui/material/IconButton"

interface ProductRowProps
{
  product: InvoiceProductItem
  onRemove: (productId: string) => void
  updateProductRow: (product: InvoiceProductItem) => void
}

export const ProductRow = ({ product, onRemove, updateProductRow }: ProductRowProps) =>
{

  const onUpdate = (updatedProduct: Partial<InvoiceProductItem>) =>
  {

    updateProductRow({ ...product, ...updatedProduct })
  }

  return(
    <Grid container spacing={1} sx={{ mb: 1, mt: 1 }}>

      <Grid item xs={8}>
        <TextField value={product.description} fullWidth placeholder="Product Description" onChange={(e: any) => onUpdate({ description: e.target.value })}>{product.description}</TextField>
      </Grid>

      <Grid item xs={2}>
        <TextField type="number" inputMode="numeric" helperText="qty." value={product.quantity} placeholder="Qty." onChange={(e: any) => onUpdate({ quantity: e.target.value })}>{product.quantity}</TextField>
      </Grid>

      <Grid item xs={2}>
        <Badge badgeContent={<IconButton onClick={() => onRemove(product?._id || product?.localId as string)}><RemoveIcon htmlColor="grey" /></IconButton>}>
        <TextField type="number" inputMode="numeric" helperText="amount" value={product.amount * product.quantity} placeholder="Amount" onChange={(e: any) => onUpdate({ amount: e.target.value })}>${product.amount}</TextField>
        </Badge>

      </Grid>


    </Grid>
  )
}