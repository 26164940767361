import createTheme from "@mui/material/styles/createTheme";

export const GREEN = "#66BB6A"
export const BLUE = "#64B5F6"
export const RED = "#EF5350"


export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#757575',
    },
    secondary: {
      main: '#FFD54F',
    },
  },
  shape: {
    borderRadius: 12
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Default color of the switch (unchecked)
          color: '#757575',
        },
        colorPrimary: {
          '&.Mui-checked': {
            // Color of the switch when checked
            color: '#FFD54F',
          },
        },
        track: {
          // Track color when unchecked
          opacity: 1,
          backgroundColor: '#757575',
          '&.Mui-checked': {
            // Track color when checked
            opacity: 1,
            backgroundColor: '#FFD54F',
          },
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#757575',
    },
    secondary: {
      main: '#FFD54F',
    },
  },
  shape: {
    borderRadius: 12
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Default color of the switch (unchecked)
          color: '#757575',
        },
        colorPrimary: {
          '&.Mui-checked': {
            // Color of the switch when checked
            color: '#FFD54F',
          },
        },
        track: {
          // Track color when unchecked
          opacity: 1,
          backgroundColor: '#757575',
          '&.Mui-checked': {
            // Track color when checked
            opacity: 1,
            backgroundColor: '#FFD54F',
          },
        },
      },
    },
  },
});