import { useEffect, useState } from "react"
import { ProductsTable } from "./products/ProductsTable"
import { ProductApi } from "../api/ProductApi"
import { Product } from "../interface/Product"
import Fab from "@mui/material/Fab"
import AddIcon from '@mui/icons-material/Add'
import { AddProductModal } from "./products/AddProductModal"
import { EditProductModal } from "./products/EditProductModal"
import Snackbar from "@mui/material/Snackbar"
import { LoadingPlaceholder } from "../components/LoadingPlaceholder"
import { Placeholder } from "../components/Placeholder"
import RepairIcon from "@mui/icons-material/Construction"

export const Services = () =>
{
  const [ products, setProducts ] = useState<Product[] | undefined>([])
  const [ loading, setLoading ] = useState(false)
  const [ showSnackRemoveProduct, setRemoveProduct ] = useState(false)

  const [ showAddProductModal, setShowAddProductModal ] = useState(false)
  const [ showEditProductModal, setShowEditProductModal ] = useState<Product | undefined>()

  useEffect(() => {
    getProducts("service")
  }, [])

  const getProducts = async (type: "product" | "service") =>
  {
    try
    {
      setLoading(true)
      const products = await new ProductApi().getProducts(type)
      setProducts(products)
      setLoading(false)
    }
    catch(error)
    {
      setLoading(false)
    }

  }

  const handleAdd = (product: Product) =>
  {
    setProducts([ product, ...products || [] ])
    setShowAddProductModal(false)
  }

  const handleUpdate = (product: Product) =>
  {
    const updated = products?.map(p => {
      if(p._id === product._id) return product;
      return p
    })

    setProducts(updated)
  }

  const handleDelete = (deletedProduct: Product) =>
  {
    setProducts(products?.filter(p => p._id !== deletedProduct._id))
    setShowEditProductModal(undefined)
    setRemoveProduct(true)
  }

  return(<div >

    { loading && <LoadingPlaceholder />}
    { !loading && (products?.length === 0 || !products) && <Placeholder text="No Services Yet" icon={<RepairIcon style={styles.icon} fontSize="large" htmlColor="grey"/>}  />}
    { products && products.length > 0 && <ProductsTable title="Services" products={products} onProductClick={setShowEditProductModal}/> }

    <Fab style={styles.fab} onClick={() => setShowAddProductModal(true)}>
        <AddIcon />
    </Fab>

    { showAddProductModal && <AddProductModal initialType="service" open={showAddProductModal} handleClose={() => setShowAddProductModal(false)} onAddSuccess={(product) => handleAdd(product)}/> }
    { showEditProductModal && <EditProductModal 

      product={showEditProductModal} 
      open={!!showEditProductModal} 
      handleClose={() => setShowEditProductModal(undefined)} 
      onUpdateSuccess={handleUpdate} 
      onDeleteSuccess={handleDelete}
      
      /> }

    <Snackbar open={showSnackRemoveProduct} message={"Removed product OK"} color="success" onClose={() => setRemoveProduct(false)} autoHideDuration={5000} />



  </div>)
}

const styles = {
  container: {

  },
  icon: {
    width: 128,
    height: 128,
  },
  fab: {
    position: "fixed" as "fixed",
    bottom: 0,
    right: 0,
    top: "auto",
    left: "auto",
    margin: 32,
  }
}