import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"
import MoneyIcon from '@mui/icons-material/Paid'


import Grid from "@mui/material/Grid"
import { useState } from "react"
import { ProductApi } from "../../api/ProductApi"
import { Product } from "../../interface/Product"
import Badge from "@mui/material/Badge"
import IconButton from "@mui/material/IconButton"

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Delete'
import Typography from "@mui/material/Typography"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { QuoteItemTypes } from "../../interface/QuoteItem"
import { QuoteItemList } from "./QuoteItemList"
import { ProductTypeSelector } from "./ProductTypeSelector"
interface AddProductModalProps
{
  open: boolean
  handleClose: () => void
  onAddSuccess: (product: Product) => void
  initialType: string
}

export const AddProductModal = ({ open, handleClose, onAddSuccess, initialType }: AddProductModalProps) =>
{

  const [ isAdding, setAdding ] = useState(false)
  const [ title, setTitle ] = useState("")
  const [ description, setDescription ] = useState("")
  const [ price, setPrice ] = useState("")
  const [ type, setType ] = useState(initialType)
  const [ selectedQuoteItems, setSelectedQuoteItems ] = useState<string[]>([])

  const [ images, setImages ] = useState<any[]>([])

  
  const addProduct = async () =>
  {
    try
    { 
      setAdding(true)
      const product = await new ProductApi().addProduct(
        {
          title,
          description,
          price: Number(price),
          images,
          quoteItems: selectedQuoteItems,
          type: type,
        })
      if(product) onAddSuccess(product)
      setAdding(false)
    }
    catch(error)
    {
      setAdding(false)
    }
  }


  const handleFileInput = (e: any) =>
  {
    const newFile = e.target.files[0]

    const reader = new FileReader();
    reader.onloadend = () => {
      // @ts-ignore
      setImages([ reader.result, ...images ])

    }

    reader.readAsDataURL(newFile);
  }

  const removeImage = (image: string) =>
  {
    const updated = images.filter(i => i !== image)
    setImages(updated)
  }

  const handleChangeQuoteItemType = (id: string, checked: boolean) => {
    if(checked) setSelectedQuoteItems(selectedQuoteItems.concat(id))
    if(!checked) setSelectedQuoteItems(selectedQuoteItems.filter(qi => qi !== id))
  }

  return(<Dialog
    fullWidth
    maxWidth="md"
    open={open}
    onClose={handleClose}>

    <DialogTitle>Add new product or service type</DialogTitle>

    <DialogContent>

      <Grid container flexDirection="column" spacing={2}> 

      <ProductTypeSelector type={type} setType={setType} />

      <Grid item>
        <Typography color="textSecondary">Title</Typography>
        <TextField autoFocus placeholder="Product or Service Name" fullWidth value={title} onChange={(e: any) => setTitle(e.target.value)}/>
      </Grid>


      <Grid item>
        <Typography color="textSecondary">Description</Typography>
        <TextField placeholder="Product or Service Description" fullWidth value={description} onChange={(e: any) => setDescription(e.target.value)}/>
      </Grid>

      <Grid item>
        <Typography color="textSecondary">Price</Typography>
        <TextField 
          InputProps={{
            startAdornment: <MoneyIcon style={{ marginBottom: 1, marginRight: 2 }} htmlColor="grey" />
          }}
          inputMode="numeric" 
          type="number" 
          placeholder="Product or Service Price inc. GST" 
          fullWidth 
          value={price} 
          onChange={(e: any) => setPrice(e.target.value)}/>
      </Grid>


      <Grid item>
        <Typography color="textSecondary" sx={{ mb: 2 }}>Upload images: max 5MB each</Typography>
        <input accept="image/*" type="file" onChange={handleFileInput}/>
      </Grid>

      <Grid item>
        { images.map((image, key) => {
          return(<Badge style={styles.badge} anchorOrigin={{ horizontal: "right", vertical: "top" }} badgeContent={<IconButton onClick={() => removeImage(image)}><RemoveIcon /></IconButton>}><img style={styles.imagePreview} src={image} key={key} /></Badge>)
        })}
      </Grid>

      <Grid item container flexDirection="row" spacing={1}>

        {Object.values(QuoteItemTypes).map((item, key) => {
          return <QuoteItemList key={key} type={item} selectedQuoteItems={selectedQuoteItems} handleChangeQuoteItemType={handleChangeQuoteItemType} />
        })}

        </Grid>
      
      </Grid>
      
    </DialogContent>

    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
      <Button variant="contained" disabled={isAdding} startIcon={isAdding ? <CircularProgress size={16} /> : <AddIcon />} onClick={addProduct}>{ isAdding ? "Adding" : "Add"}</Button>
    </DialogActions>
    
  </Dialog>)
}

const styles = {
  imagePreview: {
    width: 100,
    height: 100,
    margin: 12,
  },
  badge: {
    
  }
}