import { Quote } from "../interface/Quote";
import { HttpService } from "./HttpService";


export class QuoteApi extends HttpService
{
  get = async () =>
  {
    const response = await this.request<Quote[]>("GET", "quotes")
    return response?.data
  }

  update = async (glassType: Quote & Required<{ _id: string }>) =>
  {
    const response = await this.request<Quote>("POST", `quotes/update`, glassType)
    return response?.data
  }

  create = async (quote: Quote) =>
  {
    const response = await this.request<Quote>("POST", `quotes/add`, quote)
    return response?.data
  }

  delete = async (id: string) =>
  {
    const response = await this.request<Quote>("DELETE", `quotes?id=${id}`)
    return response?.data
  }
}