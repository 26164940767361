import { IBooking } from "../interface/IBooking";
import { HttpService } from "./HttpService";

export class BookingsApi extends HttpService
{
  getBookings = async () =>
  {
    const response  = await this.request<IBooking[]>("GET", "bookings")
    return response?.data
  }

  confirmBooking = async (bookingId: string, title?: string, startDate?: string, endDate?: string) =>
  {
    const response  = await this.request<IBooking[]>("POST", `bookings/confirm?bookingId=${bookingId}`, { startDate, endDate, title })
    return response?.data
  }

  cancelBooking = async (bookingId: string, userId: string) =>
  {
    const response  = await this.request<IBooking>("DELETE", `bookings/cancelUserBooking?bookingId=${bookingId}&userId=${userId}`)
    return response?.data
  }
}