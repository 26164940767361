import { Chat } from "../interface/Chat";
import { HttpService } from "./HttpService";

export class AdminChatApi extends HttpService
{
  getMessages = async () =>
  {
    const response = await this.request<Chat[]>("GET", "adminChat/messages")
    return response?.data
  }

  sendMessage = async (userId: string, message: string, images: string[]) =>
  {
    const response = await this.request<Chat>("POST", `adminChat/send?userId=${userId}`, { message, images })
    return response?.data
  }

  deleteConversation = async (conversationId: string) =>
  {
    const response = await this.request<Chat>("DELETE", `adminChat/conversation?conversationId=${conversationId}`)
    return response?.data
  }

  markRead = async (conversationId: string) =>
  {
    const response = await this.request<Chat>("POST", `adminChat/markRead?conversationId=${conversationId}`)
    return response?.data
  }

}