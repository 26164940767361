

export const getInitials = (text?: string) =>
{
  try
  {
    if(!text) return "SG"
    return text[0].toUpperCase() + text[1].toUpperCase()
  }
  catch(error)
  {
    return "SG"
  }
}