/* eslint-disable jsx-a11y/alt-text */
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"

import Grid from "@mui/material/Grid"
import { useEffect, useState } from "react"
import { v4 as uuid } from 'uuid'
import IconButton from "@mui/material/IconButton"

import UpdateIcon from '@mui/icons-material/Save'
import RemoveIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'

import Snackbar from "@mui/material/Snackbar"
import { ConfirmModal } from "../../components/ConfirmModal"
import { InvoiceApi } from "../../api/InvoiceApi"
import { Invoice, InvoiceProductItem, InvoiceServiceItem } from "../../interface/Invoice"
import Typography from "@mui/material/Typography"
import { UserApi } from "../../api/UserApi"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { ProductRow } from "./ProductRow"
import { ServiceRow } from "./ServiceRow"
import { User } from "../../interface/User"

interface EditInvoiceModalProps
{
  open: boolean
  invoice: Invoice
  handleClose: () => void
  onUpdateSuccess: (product: Invoice) => void
  onDeleteSuccess: (product: Invoice) => void
}

export const EditInvoiceModal = ({ open, invoice, handleClose, onUpdateSuccess, onDeleteSuccess }: EditInvoiceModalProps) =>
{

  const [ isUpdating, setUpdating ] = useState(false)
  const [ isDeleting, setDeleting ] = useState(false)
  const [ showUpdatedSuccess, setShowUpdatedSuccess ] = useState(false)
  const [ showConfirmRemove, setShowConfirmRemove ] = useState(false)
  const [ products, setProducts ] = useState<InvoiceProductItem[]>([])
  const [ services, setServices ] = useState<InvoiceServiceItem[]>([])
  const [ isGettingCustomers, setGettingCustomers ] = useState(false)
  const [ customers, setCustomers ] = useState<User[] | undefined>()
  const [ customer, setCustomer ] = useState<User | undefined>()


  const [ title, setTitle ] = useState("")
  const [ description, setDescription ] = useState("")

  useEffect(() => {

    setTitle(invoice.title)
    setDescription(invoice.description)
    setProducts(invoice.products)
    setServices(invoice.services)

    getCustomers()


  }, [])

  useEffect(() => {

    setCustomer(customers?.find(c => c._id === invoice.userId))

  }, [ customers])


  const getCustomers = async () =>
  {
    try
    {

      setGettingCustomers(true)
      const customers = await new UserApi().getUsers("customer")
      setCustomers(customers)
      setGettingCustomers(false)
    }
    catch(error)
    {
      setGettingCustomers(false)
    }
  }
  

  const updateInvoice = async () =>
  {
    try
    { 
      setUpdating(true)
      const updateResult = await new InvoiceApi().updateInvoice({ _id: invoice?._id, title, description, userId: customer?._id, services: services, products: products })
      if(updateResult) onUpdateSuccess({ ...updateResult, email: customer?.email, company: customer?.name })
      setUpdating(false)
      setShowUpdatedSuccess(true)
    }
    catch(error)
    {
      setUpdating(false)
    }
  }

  const deleteInvoice = async () =>
  {
    try
    { 
      setDeleting(true)
      if(!invoice._id) return
      const invoiceDeleteResult = await new InvoiceApi().deleteInvoice(invoice?._id)
      if(invoiceDeleteResult) onDeleteSuccess(invoice)
      setDeleting(false)
    }
    catch(error)
    {
      setDeleting(false)
    }
  }

  const addProductRow = () =>
  {
      setProducts([...products, { localId: uuid(), description: "", amount: 0, quantity: 0 }])
  }

  const removeProductItem = (localId: string) =>
  {
    setProducts(products.filter(p => p.localId !== localId))
  }

  const updateProductRow = (product: InvoiceProductItem) =>
  {
    const updated = products.map(p => {
      if(p.localId === product.localId) return product;
      return p
    })

    setProducts(updated)
  }

  const addServiceRow = () =>
  {
      setServices([...services, { localId: uuid(), description: "", amount: 0, duration: 0 }])
  }

  const removeServiceRow = (_id?: string, localId?: string) =>
  {

   if(_id) setServices(services.filter(s => s._id !== _id))
   if(localId) setServices(services.filter(s => s.localId !== localId))
  }

  const updateServiceRow = (service: InvoiceServiceItem) =>
  {
    if(service._id)
    {
      const updated = services.map(p => {
        if(p._id === service._id) return service;
        return p
      })
      setServices(updated)

    }

    if(service.localId)
    {
      const updated = services.map(p => {
        if(p.localId === service.localId) return service;
        return p
      })
      setServices(updated)

    }
  }

  const getInvoiceTotal = () =>
  {
    let total = 0;
    for(const product of products)
    {
      total = total + (product.amount * product.quantity)
    }

    for(const service of services)
    {
      total = total + (service.amount * service.duration)
    }

    return total
  }

  const getGST = () =>
  {
    return (getInvoiceTotal() * 0.1).toFixed(2)
  }


  return(<Dialog
    fullWidth
    maxWidth="md"
    open={open}
    onClose={handleClose}>

    <DialogTitle>Edit Invoice</DialogTitle>

    <DialogContent>

      <Grid container flexDirection="column" spacing={2}> 

      <Grid item>
        <TextField autoFocus placeholder="Invoice Title" fullWidth value={title} onChange={(e: any) => setTitle(e.target.value)}/>
      </Grid>


      <Grid item>
        <TextField size="small" placeholder="Invoice Description" fullWidth value={description} onChange={(e: any) => setDescription(e.target.value)}/>
      </Grid>

      <Grid item>
        <Typography color="textSecondary">Change new customer or person. Current is {customers?.find(c => c._id === customer?._id)?.email}</Typography>
        <Select fullWidth value={customer?._id} >
          {customers?.map((customer, key) => <MenuItem key={key} onClick={() => setCustomer(customer)} value={customer._id}>{customer.email}</MenuItem>)}
        </Select>
      </Grid>

      <Grid item>

        <Typography color="textSecondary">Products</Typography>
        <IconButton onClick={addProductRow}><AddIcon /></IconButton>
        { products?.map((p, key) => <ProductRow key={key} product={p} onRemove={removeProductItem} updateProductRow={updateProductRow} />  )}
      </Grid>

      <Grid item>

        <Typography color="textSecondary">Services</Typography>
        <IconButton onClick={addServiceRow}><AddIcon /></IconButton>
        { services?.map((s, key) => <ServiceRow key={key} service={s} onRemove={removeServiceRow} updateServiceRow={updateServiceRow} />  )}
      </Grid>

      <Typography fontWeight="700" fontSize={32} textAlign="end" sx={{ m: 1}}>Invoice Total : ${getInvoiceTotal().toFixed(2)} inc. GST</Typography>
      <Typography color="textSecondary" fontWeight="600" fontSize={16} textAlign="end" sx={{ m: 1}}>GST amount: ${getGST()}</Typography>
      
      </Grid>
      
    </DialogContent>

    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
      <div style={{ flex: 1 }}/>
      <Button disabled={isDeleting} startIcon={isDeleting ? <CircularProgress size={16} /> : <RemoveIcon />} onClick={() => setShowConfirmRemove(true)}>{ isDeleting ? "Delete" : "Delete"}</Button>
      <Button variant="contained" disabled={isUpdating} startIcon={isUpdating ? <CircularProgress size={16} /> : <UpdateIcon />} onClick={updateInvoice}>{ isUpdating ? "Updating" : "Update"}</Button>
    </DialogActions>

    <Snackbar open={showUpdatedSuccess} message="Updated invoice OK" color="success" onClose={() => setShowUpdatedSuccess(false)} autoHideDuration={5000} />

    <ConfirmModal 
      title="Remove Invoice" 
      description="Are you sure you want to remove this invoice?" 
      open={showConfirmRemove}
      handleClose={() => setShowConfirmRemove(false)}
      onConfirm={deleteInvoice} />
    
  </Dialog>)
}