import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"

import BookingIcon from "@mui/icons-material/BookOnline"
import AccountIcon from "@mui/icons-material/AccountBox"
import InvoiceIcon from "@mui/icons-material/ReceiptLong"
import ProductIcon from "@mui/icons-material/AddBusiness"
import Avatar from "@mui/material/Avatar"


interface DashboardCardProps
{
  title: string
  description: string
  color: string
  color2: string
  value: string
  iconType: "invoice" | "product" | "customer" | "booking"
}

export const DashboardCard = ({ title, description, color, iconType, value, color2 }: DashboardCardProps) =>
{

  const getIcon = () =>
  {
      if(iconType === "booking") return <BookingIcon htmlColor="white" fontSize="large" />
      if(iconType === "product") return <ProductIcon htmlColor="white" fontSize="large" />
      if(iconType === "invoice") return <InvoiceIcon htmlColor="white" fontSize="large" />
      if(iconType === "customer") return <AccountIcon htmlColor="white" fontSize="large" />
  }
  return(
    <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
    <Card style={{ ...styles.container, backgroundColor: color }}>

      {getIcon()}
      <Typography style={styles.titleText}>{title}</Typography>
      <Typography style={styles.subText}>{description}</Typography>


      <Avatar style={{ ...styles.avatar, backgroundColor: color2 }} >{value}</Avatar>

      
    </Card>
    </Grid>
  )
}

const styles = {
  titleText: {
    color: "white",
    fontSize: 18,
    fontWeight: "700"
  },
  subText: {
    color: '#EEEEEE'
  },
  container: {
    height: 200,
    padding: 24,
  },
  avatar: {
    fontWeight: "700",
    fontSize: 20,
    width: 128,
    height: 128,
    float: "right" as 'right',
    paddingBottom: 12,

  }
}