

export enum QuoteItemTypes
{
  FRAME_TYPES = "Frame Types",
  GLASS_TYPES = "Glass Types",
  CUT_OUTS = "Cut Outs",
  EDGES = "Edges",
  FIXING = "Fixing",
  SHAPE = "Shape",
  FRAME_COLOUR = "Frame Colour",
  PET_DOOR_SIZE = "Pet Door Sizes",
  FLOAT_TYPE = "Float Type",
  SHOWER_TYPES = "Shower Types",
  SHOWER_POSITIONS = "Shower Positions",
  SHELVES = "Shelves",
  SPLASH_BACKS = "Spashbacks",
  NEW_DOORS = "New Doors"
}


export interface QuoteItem
{
  _id?: string;
  type: QuoteItemTypes;
  title: string;
  image: string;
  cost: number;
}