import { Invoice } from "../interface/Invoice";

export const getInvoiceTotal = (invoice: Invoice) =>
{
  let total = 0;
  for(const product of invoice.products)
  {
    total = total + (product.amount * product.quantity)
  }

  for(const service of invoice.services)
  {
    total = total + (service.amount * service.duration)
  }

  return total
}