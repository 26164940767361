import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import Typography from "@mui/material/Typography"
import { User } from "../../interface/User"
import Paper from "@mui/material/Paper"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import TableHead from "@mui/material/TableHead"
import Table from "@mui/material/Table"
import { getInitials } from "../../util/getInitials"
import Avatar from "@mui/material/Avatar"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"

import moment from "moment"



interface CustomersTableProps
{
  users: User[]
  onDeleteUser: (userId: string) => void
}

export const CustomersTable = ({ users, onDeleteUser }: CustomersTableProps) =>
{
  

  return (
    <>
    <Typography color="textSecondary" fontWeight="800" fontSize={24}>Customers and Admins</Typography>
    <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell></TableCell>
            <TableCell align="left" style={{ textWrap: "nowrap"}}>Email</TableCell>
            <TableCell align="right">Company or name</TableCell>
            <TableCell align="right">Type</TableCell>

            <TableCell align="right">Created</TableCell>
            <TableCell align="right">Remove</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((row) => (
            <TableRow
              hover
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

               <TableCell component="th" scope="row" >
               <Avatar style={row.isAdmin ? styles.avatarAdmin : styles.avatarCustomer}>{getInitials(row.email)}</Avatar>
               </TableCell>
              <TableCell component="th" scope="row" >{row.email}</TableCell>
              <TableCell align="right" >{row.name}</TableCell>
              <TableCell align="right" >{row.isAdmin ? "Admin User" : "Customer"}</TableCell>
              <TableCell align="right" >{moment(row.createdAt).fromNow()}</TableCell>
              <TableCell align="right" ><IconButton onClick={() => onDeleteUser(row?._id as string)}><DeleteIcon /></IconButton></TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
}

const styles = {
  firstItem: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    flexDirection: 'row' as 'row',
  },
  avatarAdmin: {
    marginRight: 6,
    backgroundColor: "#2196F3"
  },
  avatarCustomer: {
    marginRight: 6,
    backgroundColor: "#66BB6A"
  }
}