import LoginIcon from "@mui/icons-material/Login"

import  Grid from "@mui/material/Grid"
import  CssBaseline from "@mui/material/CssBaseline"
import  Paper from "@mui/material/Paper"
import  Box from "@mui/material/Box"
import  Typography from "@mui/material/Typography"
import  TextField from "@mui/material/TextField"
import  Button from "@mui/material/Button"
import  FormControlLabel from "@mui/material/FormControlLabel"
import  Checkbox from "@mui/material/Checkbox"
import Avatar from "@mui/material/Avatar"


import { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { clearLoginError, performLogin } from "../redux/creators/userCreators"
import { ApplicationState } from "../interface/ApplicationState"
import Snackbar from "@mui/material/Snackbar"
import { CircularProgress } from "@mui/material"
import { ResetPasswordModal } from "../components/modal/ResetPasswordModal"

export const LoginScreen = () =>
{
  const [ email, setEmail ] = useState("")
  const [ password, setPassword ] = useState("")
  const [ showResetModal, setShowResetModal ] = useState(false)

  const dispatch = useDispatch()

  const loginError = useSelector((state: ApplicationState) => state.user.loginError)
  const isLoggingIn = useSelector((state: ApplicationState) => state.user.isLoggingIn)


  const handleLogin = async () =>
  {
    if(email && password) dispatch(performLogin(email, password))
  }

  return(
    <Grid container component="main" sx={{ height: '100vh' }}>
    <CssBaseline />
    <Grid
      item
      xs={false}
      sm={4}
      md={7}
      sx={{
        backgroundImage: `url(${require("../assets/login.jpg")})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 2, bgcolor: 'secondary.main' }}>
          SG
        </Avatar>
        
        <img src={require("../assets/sg.png")} alt="Select Glass Logo" />
        
        <Typography  variant="subtitle1" sx={{ mt: 2 }} color="textSecondary">
          Sign in
        </Typography>

        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            onChange={(e: any) => setEmail(e.target.value)}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            onChange={(e: any) => setPassword(e.target.value)}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            onClick={handleLogin}
            fullWidth
            disabled={isLoggingIn}
            startIcon={isLoggingIn ? <CircularProgress size={16} /> : <LoginIcon />}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            { isLoggingIn ? "Logging in" : "Login"}
          </Button>
          <Grid container>
            <Grid item xs>
              <Button onClick={() => setShowResetModal(true)}>
                Forgot password?
              </Button>
            </Grid>
         
          </Grid>

        </Box>
      </Box>
    </Grid>

    <Snackbar open={!!loginError} message={loginError} color="error" onClose={() => dispatch(clearLoginError())} autoHideDuration={5000} />

    { showResetModal && <ResetPasswordModal open={showResetModal} handleClose={() => setShowResetModal(false)} /> }

  </Grid>
  )
}