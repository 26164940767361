import { getImagePath } from "../util/getImagePath"


interface ImagePreviewProps
{
  images: string[]
}
export const ImagePreview = ({ images }: ImagePreviewProps) =>
{

  return(
    <div style={styles.container}>
      { images.map((image, key) => <img src={getImagePath(image)} key={key} style={styles.image} />)}
    </div>
  )
}

const styles = {
  container: {
    flex: 1,
    flexDirection: "row" as 'row',
  },
  image: {
    height: 30,
    width: 30,
    borderRadius: 12,
  }
}