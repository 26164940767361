import { Purchase } from "../interface/Purchase";
import { HttpService } from "./HttpService";


export class PurchaseApi extends HttpService
{
  getUserPurchases = async () =>
  {
    const response = await this.request<Purchase[]>("GET", `admin/userPurchases`)
    return response?.data
  }

}