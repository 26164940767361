import { useEffect, useState } from "react"
import { PurchaseApi } from "../api/PurchaseApi"
import { Purchase } from "../interface/Purchase"
import { PurchasesTable } from "./purchases/PurchasesTable"
import { LoadingPlaceholder } from "../components/LoadingPlaceholder"
import { Placeholder } from "../components/Placeholder"
import ShoppingIcon from "@mui/icons-material/ShoppingBasket"


export const Purchases = () =>
{
  const [ purchases, setPurchases ] = useState<Purchase[]>([])
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {

    getAllPurchases()

  }, [])

  const getAllPurchases = async () =>
  {
    try
    {
      setLoading(true)
      const allPurchases = await new PurchaseApi().getUserPurchases()
      if(allPurchases) setPurchases(allPurchases)
      setLoading(false)

    }
    catch(error)
    {
      setLoading(false)
    }
  }

  return(<div>


    { loading && <LoadingPlaceholder />}
    { !loading && (purchases?.length === 0 || !purchases) && <Placeholder text="No Customer Purchases Yet" icon={<ShoppingIcon style={styles.icon} fontSize="large" htmlColor="grey"/>}  />}
    { purchases && purchases.length > 0 && <PurchasesTable purchases={purchases} /> }
    

  </div>)
}

const styles = {
  icon: {
    width: 128,
    height: 128,
  },

}