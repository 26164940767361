import { Chat } from "../../../interface/Chat";


export const updateMessages = (messages: Chat[], updatedChat: Chat) =>
{
  const updatedMessages = messages.map(m => {
    if(m._id === updatedChat._id) return updatedChat
    return m
  })

  return updatedMessages
}