import { Chat } from "../../interface/Chat"
import { ChatActions } from "../actions/ChatActions"


export const setCurrentChat = (conversationId: string) =>
{
  return {
    type: ChatActions.SET_CURRENT_CHAT,
    data: { conversationId }
  }
}

export const getMessagesStart = () =>
{
  return {
    type: ChatActions.GET_ALL_MESSAGES_START
  }
}

export const getMessagesSuccess = (messages: Chat[]) =>
{
  return {
    type: ChatActions.GET_ALL_MESSAGES_SUCCESS,
    data: { messages }
  }
}

export const getMessagesError = (error: string) =>
{
  return {
    type: ChatActions.GET_ALL_MESSAGES_ERROR,
    error
  }
}


export const sendMessageStart = (userId: string, message: string, images: string[]) =>
{
  return {
    type: ChatActions.SEND_MESSSAGE_START,
    data: { userId, message, images }
  }
}

export const sendMessageSuccess = (chat: Chat) =>
{
  return {
    type: ChatActions.SEND_MESSSAGE_SUCCESS,
    data: { chat }
  }
}

export const sendMessageError = (error: string) =>
{
  return {
    type: ChatActions.SEND_MESSSAGE_ERROR,
    error
  }
}

export const deleteConversationStart = (conversationId: string) =>
{
  return {
    type: ChatActions.DELETE_CONVERSATION_START,
    data: { conversationId }
  }
}

export const deleteConversationSuccess = (chat: Chat) =>
{
  return {
    type: ChatActions.DELETE_CONVERSATION_SUCCESS,
    data: { chat }
  }
}

export const deleteConversationError = (error: string) =>
{
  return {
    type: ChatActions.DELETE_CONVERSATION_ERROR,
    error
  }
}

export const markReadStart = (conversationId: string) =>
{
  return {
    type: ChatActions.MARK_READ_START,
    data: { conversationId }
  }
}

export const markReadSuccess = (chat: Chat) =>
{
  return {
    type: ChatActions.MARK_READ_SUCCESS,
    data: { chat }
  }
}

export const markReadError = (error: string) =>
{
  return {
    type: ChatActions.MARK_READ_ERROR,
    error
  }
}