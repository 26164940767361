import { createStore, applyMiddleware } from "redux"
import createSagaMiddleware from 'redux-saga'
import { rootReducer } from "../rootReducer"
import { rootSaga } from '../sagas/sagas'
import "regenerator-runtime/runtime";

const sagaMiddleware = createSagaMiddleware();

export const store = createStore<any, any>(rootReducer, applyMiddleware(sagaMiddleware))

sagaMiddleware.run(rootSaga)