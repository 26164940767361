import { LoginResponse } from "../interface/LoginResponse";
import { HttpService } from "./HttpService";


export class LoginApi extends HttpService
{
  login = async (email: string, password: string) =>
  {
    const response = await this.request<LoginResponse>("POST", "login", { email, password })
    if(response) return response.data
  }
}