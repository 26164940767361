import { put, takeLatest } from "redux-saga/effects";
import { ChatActions } from "../actions/ChatActions";
import { AdminChatApi } from "../../api/AdminChatApi";
import { deleteConversationError, deleteConversationSuccess, getMessagesError, getMessagesSuccess, markReadError, markReadSuccess, sendMessageError, sendMessageSuccess } from "../creators/chatCreators";


function* getAllMessages(): any
{
  try
  {
    const messages = yield new AdminChatApi().getMessages()
    yield put(getMessagesSuccess(messages))
  }
  catch(error)
  {
    const errorCast = error as any
    yield put(getMessagesError(errorCast?.message?.response?.data?.error || "Looks like something went wrong"))
  }
}

function* sendMessage(action: any): any
{
  try
  {
    const chat = yield new AdminChatApi().sendMessage(action.data.userId, action.data.message, action.data.images)
    console.log("CHAT", chat)
    yield put(sendMessageSuccess(chat))
  }
  catch(error)
  {
    const errorCast = error as any
    yield put(sendMessageError(errorCast?.message?.response?.data?.error || "Looks like something went wrong"))
  }
}

function* deleteConversation(action: any): any
{
  try
  {
    const chat = yield new AdminChatApi().deleteConversation(action.data.conversationId)
    yield put(deleteConversationSuccess(chat))
  }
  catch(error)
  {
    const errorCast = error as any
    yield put(deleteConversationError(errorCast?.message?.response?.data?.error || "Looks like something went wrong"))
  }
}

function* markRead(action: any): any
{
  try
  {
    const chat = yield new AdminChatApi().markRead(action.data.conversationId)
    yield put(markReadSuccess(chat))
  }
  catch(error)
  {
    const errorCast = error as any
    yield put(markReadError(errorCast?.message?.response?.data?.error || "Looks like something went wrong"))
  }
}

export function* chatSaga()
{
  yield takeLatest(ChatActions.GET_ALL_MESSAGES_START, getAllMessages)
  yield takeLatest(ChatActions.SEND_MESSSAGE_START, sendMessage)
  yield takeLatest(ChatActions.DELETE_CONVERSATION_START, deleteConversation)
  yield takeLatest(ChatActions.MARK_READ_START, markRead)
}