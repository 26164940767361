import { User } from "../interface/User";
import { HttpService } from "./HttpService";


export class UserApi extends HttpService
{
  getProfile = async () =>
  {
    const response = await this.request<User>("GET", "user")
    if(response) return response.data
  }

  getUsers = async (type?: "customer" | "isAdmin") =>
  {
    const response = await this.request<User[]>("GET", `admin/users?type=${type}`)
    if(response) return response.data
  }

  updateUser = async (user: Partial<User>) =>
  {
    const response = await this.request<User>("POST", `user`, user)
    if(response) return response.data
  }

  createUser = async (user: Partial<User>) =>
  {
    const response = await this.request<User>("POST", `admin/create`, user)
    if(response) return response.data
  }

  deleteUser = async (userId: string) =>
  {
    const response = await this.request<User>("DELETE", `admin/delete?userId=${userId}`)
    if(response) return response.data
  }
}