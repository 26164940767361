import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"
import ConfirmIcon from '@mui/icons-material/Check'
import CancelIcon from '@mui/icons-material/Clear'

import Grid from "@mui/material/Grid"
import { IBooking } from "../../interface/IBooking"
import { useEffect, useState } from "react"
import { BookingsApi } from "../../api/BookingsApi"
import Snackbar from "@mui/material/Snackbar"
import Typography from "@mui/material/Typography"

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ConfirmModal } from "../../components/ConfirmModal"
import dayjs from 'dayjs'
interface BookingModalProps
{
  open: boolean
  booking: IBooking
  handleClose: () => void
  getDashboard: (showLoad: boolean) => void

}

export const BookingModal = ({ open, handleClose, booking, getDashboard }: BookingModalProps) =>
{

  const [ title, setTitle ] = useState(booking.title)
  const [ userId, setUserId ] = useState(booking.userId)
  const [ startDate, setStartDate ] = useState(booking.startDate)
  const [ endDate, setEndDate ] = useState(booking.endDate)

  const [ confirming, setConfirming ] = useState(false)
  const [ cancelling, setCancelling ] = useState(false)
  const [ showCancelModal, setShowCancelBooking ] = useState(false)
  const [ showConfirmBooking, setShowConfirmBooking ] = useState(false)
  const [ showConfirmSnack, setShowConfirmedSnack ] = useState("")


  const onConfirm = async () =>
  {
    try
    {
      setConfirming(true)
      if(!booking._id) return;
      await new BookingsApi().confirmBooking(booking._id, title, startDate, endDate)
      setConfirming(false)
      setShowConfirmBooking(false)
      setShowConfirmedSnack("Booking has been confirmed. Push sent to customer.")
      getDashboard(false)
      handleClose()
    }
    catch(error)
    {
      setConfirming(false)
      setShowConfirmedSnack("Looks like there wan an issue confirming the booking. Please try again")
      setShowConfirmBooking(false)

    }
  }

  const onCancelBooking = async () =>
  {
    try
    {
      setCancelling(true)
      if(!booking._id) return;
      await new BookingsApi().cancelBooking(booking._id, booking.userId)
      setCancelling(false)
      setShowCancelBooking(false)
      setShowConfirmedSnack("Booking has been cancelled. Push sent to customer.")
      getDashboard(false)
      handleClose()
    }
    catch(error)
    {
      setShowConfirmedSnack("Looks like there wan an issue cancelling the booking. Please try again")
      setShowCancelBooking(false)
      setCancelling(false)
    }
  }

  return(<Dialog
    fullWidth
    maxWidth="md"
    open={open}
    onClose={handleClose}>

    <DialogTitle>Manage Booking</DialogTitle>

    <DialogContent>

      <Grid container flexDirection="column" spacing={2}> 

      <Grid item>
        <Typography variant="caption" color="textSecondary">Status</Typography>
       <Typography fontWeight={700} color="textSecondary">{booking.isConfirmed ? "Confirmed" : "Unconfirmed"}</Typography>
      </Grid>

      <Grid item>
        <Typography variant="caption" color="textSecondary">Customer email</Typography>
       <Typography fontWeight={700} color="textSecondary">{booking.userEmail}</Typography>
      </Grid>

      <Grid item>
        <Typography variant="caption" color="textSecondary">Company name</Typography>
       <Typography>{booking.userCompany || "-"}</Typography>
      </Grid>

      <Grid item>
        <TextField autoFocus placeholder="Booking Name or address" fullWidth value={title} onChange={(e: any) => setTitle(e.target.value)}/>
      </Grid>

      <Grid item>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker 
          format="ddd DD/MM/YYYY h:mm a"
          value={dayjs(startDate)} 
          label="Booking Start Date and Time" 
          onChange={(e: any) => setStartDate(e.toISOString())}/>

      <DateTimePicker 
          format="ddd DD/MM/YYYY h:mm a"
          value={dayjs(endDate)} 
          label="Booking End Time" 
          onChange={(e: any) => setEndDate(e.toISOString())}/>
        </DemoContainer>

      </LocalizationProvider>
      </Grid>

      </Grid>
      
    </DialogContent>

    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
      <div style={{ flex: 1 }} />
      <Button color="warning" disabled={cancelling} startIcon={cancelling ? <CircularProgress size={16} /> : <CancelIcon />} onClick={() => setShowCancelBooking(true)}>{ cancelling ? "Cancelling..." : "Cancel Booking"}</Button>
      <Button color="success" variant="contained" disabled={confirming} startIcon={confirming ? <CircularProgress size={16} /> : <ConfirmIcon />} onClick={() => setShowConfirmBooking(true)}>{ confirming ? "Confirming..." : "Confirm BOoking"}</Button>
    </DialogActions>

    <Snackbar open={!!showConfirmSnack} message={showConfirmSnack} color="error" onClose={() => setShowConfirmedSnack("")} autoHideDuration={5000} />

    { showConfirmBooking && <ConfirmModal open={showConfirmBooking} onConfirm={onConfirm} title="Confirm Booking" description="Are you sure you want to confirm this booking. User will receive confirmation" handleClose={() => setShowConfirmBooking(false)}/> }
    { showCancelModal && <ConfirmModal open={showCancelModal} onConfirm={onCancelBooking} title="Cancel Booking" description="Are you sure you want to cancel this booking? User will receive cancellation push msg." handleClose={() => setShowCancelBooking(false)}/> }

    
  </Dialog>)
}
