import { HttpService } from "./HttpService";


export class ResetApi extends HttpService
{
  requestReset = async (email: string) =>
  {
    const response = await this.request<{ result: string }>("POST", `reset/request?email=${email}`)
    return response?.data
  }

  changePassword = async (email: string, mfaCode: number, password: string, confirmPassword: string) =>
  {
    const response = await this.request<{ result: string }>("POST", `reset/changePassword?email=${email}&mfaCode=${mfaCode}`, { password, confirmPassword })
    return response?.data
  }

}