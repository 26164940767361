import Card from "@mui/material/Card"
import { QuoteItem } from "../../../interface/QuoteItem"
import { getImagePath } from "../../../util/getImagePath"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import CardActionArea from "@mui/material/CardActionArea"

interface QuoteTypeItemProps
{
  quoteItem: QuoteItem
  onClick: (type: QuoteItem) => void
}

export const QuoteTypeItem = ({ quoteItem, onClick }: QuoteTypeItemProps) => {
  return(
    <Grid item xs={12} sm={12} md={12} lg={4} xl={2} >
      <Card style={styles.container}>
      <CardActionArea onClick={() => onClick(quoteItem)}>

        <img src={getImagePath(quoteItem.image)} alt="quoteType" style={styles.image} />
        <Typography sx={{ ml: 1 }} variant="subtitle1"fontWeight={700}>{quoteItem.title}</Typography>
        <Typography sx={{ ml: 1 }} variant="subtitle2" >${quoteItem.cost} m2</Typography>
        </CardActionArea>

      </Card>
  </Grid>)
}

const styles = {
  container: {
    margin: 6,
    borderRadius: 12,
  },
  image: {
    objectFit: "cover" as 'cover',
    height: 120,
    width: "100%",
  }
}