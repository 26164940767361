/* eslint-disable jsx-a11y/alt-text */
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"


import Grid from "@mui/material/Grid"
import { useEffect, useState } from "react"
import { ProductApi } from "../../api/ProductApi"
import { Product } from "../../interface/Product"
import Badge from "@mui/material/Badge"
import IconButton from "@mui/material/IconButton"

import UpdateIcon from '@mui/icons-material/Save'
import RemoveIcon from '@mui/icons-material/Delete'
import MoneyIcon from '@mui/icons-material/Paid'

import Snackbar from "@mui/material/Snackbar"
import { getImagePath } from "../../util/getImagePath"
import { ConfirmModal } from "../../components/ConfirmModal"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Typography from "@mui/material/Typography"
import { QuoteItemTypes } from "../../interface/QuoteItem"
import { QuoteItemList } from "./QuoteItemList"
import { ProductTypeSelector } from "./ProductTypeSelector"

interface EditProductModalProps
{
  open: boolean
  product: Product
  handleClose: () => void
  onUpdateSuccess: (product: Product) => void
  onDeleteSuccess: (product: Product) => void
}

export const EditProductModal = ({ open, product, handleClose, onUpdateSuccess, onDeleteSuccess }: EditProductModalProps) =>
{

  const [ isUpdating, setUpdating ] = useState(false)
  const [ isDeleting, setDeleting ] = useState(false)
  const [ showUpdatedSuccess, setShowUpdatedSuccess ] = useState(false)
  const [ showConfirmRemove, setShowConfirmRemove ] = useState(false)

  const [ title, setTitle ] = useState("")
  const [ description, setDescription ] = useState("")
  const [ price, setPrice ] = useState("")
  const [ type, setType ] = useState(product.type)
  const [ selectedQuoteItems, setSelectedQuoteItems ] = useState<string[]>([])

  const [ images, setImages ] = useState<any[]>([])

  useEffect(() => {

    setTitle(product.title)
    setDescription(product.description)
    setPrice(String(product.price.toFixed(2)))
    setImages(product.images)
    setSelectedQuoteItems(product.quoteItems || [])

  }, [])

  const updateProduct = async () =>
  {
    try
    { 
      setUpdating(true)
      const updateResult = await new ProductApi().updateProduct(
          {
            _id: product?._id,
            title, description,
            price: Number(price),
            images,
            type,
            quoteItems: selectedQuoteItems,
          })
      if(updateResult) onUpdateSuccess(updateResult)
      setUpdating(false)
      setShowUpdatedSuccess(true)
    }
    catch(error)
    {
      setUpdating(false)
    }
  }

  const deleteProduct = async () =>
  {
    try
    { 
      setDeleting(true)
      if(!product._id) return
      const productDeleteResult = await new ProductApi().deleteProduct(product?._id)
      if(productDeleteResult) onDeleteSuccess(product)
      setDeleting(false)
    }
    catch(error)
    {
      setDeleting(false)
    }
  }

  const handleFileInput = (e: any) =>
  {
    const newFile = e.target.files[0]

    const reader = new FileReader();
    reader.onloadend = () => {

      console.log("Setting new image", reader.result)
      setImages([ reader.result, ...images ])

    }

    reader.readAsDataURL(newFile);
  }

  const removeImage = (image: string) =>
  {
    const updated = images.filter(i => i !== image)
    setImages(updated)
  }

  const handleChangeQuoteItemType = (id: string, checked: boolean) => {
    if(checked) setSelectedQuoteItems(selectedQuoteItems.concat(id))
    if(!checked) setSelectedQuoteItems(selectedQuoteItems.filter(qi => qi !== id))
  }


  return(<Dialog
    fullWidth
    maxWidth="md"
    open={open}
    onClose={handleClose}>

    <DialogTitle>Edit Product</DialogTitle>

    <DialogContent>

      <Grid container flexDirection="column" spacing={2}> 

      <ProductTypeSelector type={type} setType={setType} />

      <Grid item>
        <Typography color="textSecondary">Title</Typography>
        <TextField placeholder="Product or Service Name"  fullWidth value={title} onChange={(e: any) => setTitle(e.target.value)}/>
      </Grid>


      <Grid item>
        <Typography color="textSecondary" >Description</Typography>
        <TextField placeholder="Product or Service Description" fullWidth value={description} onChange={(e: any) => setDescription(e.target.value)}/>
      </Grid>

      <Grid item>
      <Typography color="textSecondary">Price</Typography>
        <TextField 
          InputProps={{
            startAdornment: <MoneyIcon style={{ marginBottom: 1, marginRight: 2 }} htmlColor="grey" />
          }}
          inputMode="numeric" 
          type="number" 
          placeholder="Product or Service Price inc. GST" 
          fullWidth value={price} 
          onChange={(e: any) => setPrice(e.target.value)}/>
      </Grid>

      <Grid item>
        <Typography color="textSecondary" sx={{ mb: 2 }}>Add more images: max 5MB each</Typography>
        <input accept="image/*" type="file" onChange={handleFileInput}/>
      </Grid>

      <Grid item>
      <Typography color="textSecondary" sx={{ mb: 2 }}>Current Images</Typography>

        { images.map((image, key) => {
          return(<Badge 
            style={styles.badge} 
            anchorOrigin={{ horizontal: "right", vertical: "top" }} 
            badgeContent={<IconButton onClick={() => removeImage(image)}><RemoveIcon /></IconButton>}>
              <img style={styles.imagePreview} src={image.startsWith("data:") ? image : getImagePath(image)} key={key} />
              </Badge>)
        })}
      </Grid>

      <Grid item container flexDirection="row" spacing={1}>
      {Object.values(QuoteItemTypes).map((item, key) => {
          return <QuoteItemList key={key} type={item} selectedQuoteItems={selectedQuoteItems} handleChangeQuoteItemType={handleChangeQuoteItemType} />
        })}
      </Grid>
      
      </Grid>
      
    </DialogContent>

    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
      <div style={{ flex: 1 }}/>
      <Button disabled={isDeleting} startIcon={isDeleting ? <CircularProgress size={16} /> : <RemoveIcon />} onClick={() => setShowConfirmRemove(true)}>{ isDeleting ? "Delete" : "Delete"}</Button>
      <Button variant="contained" disabled={isUpdating} startIcon={isUpdating ? <CircularProgress size={16} /> : <UpdateIcon />} onClick={updateProduct}>{ isUpdating ? "Updating" : "Update"}</Button>
    </DialogActions>

    <Snackbar open={showUpdatedSuccess} message="Updated product OK" color="success" onClose={() => setShowUpdatedSuccess(false)} autoHideDuration={5000} />

    <ConfirmModal 
      title="Remove Product" 
      description="Are you sure you want to remove this product?" 
      open={showConfirmRemove}
      handleClose={() => setShowConfirmRemove(false)}
      onConfirm={deleteProduct} />
    
  </Dialog>)
}

const styles = {
  imagePreview: {
    width: 100,
    height: 100,
    margin: 12,
  },
  badge: {
    
  }
}