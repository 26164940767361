


export enum ChatActions
{
  GET_ALL_MESSAGES_START = "GET_ALL_MESSAGES_START",
  GET_ALL_MESSAGES_SUCCESS = "GET_ALL_MESSAGES_SUCCESS",
  GET_ALL_MESSAGES_ERROR = "GET_ALL_MESSAGES_ERROR",

  SEND_MESSSAGE_START = "SEND_MESSSAGE_START",
  SEND_MESSSAGE_SUCCESS = "SEND_MESSSAGE_SUCCESS",
  SEND_MESSSAGE_ERROR = "SEND_MESSSAGE_ERROR",

  DELETE_CONVERSATION_START = "DELETE_CONVERSATION_START",
  DELETE_CONVERSATION_SUCCESS = "DELETE_CONVERSATION_SUCCESS",
  DELETE_CONVERSATION_ERROR = "DELETE_CONVERSATION_ERROR",

  MARK_READ_START = "MARK_READ_START",
  MARK_READ_SUCCESS = "MARK_READ_SUCCESS",
  MARK_READ_ERROR = "MARK_READ_ERROR",

  SET_CURRENT_CHAT = "SET_CURRENT_CHAT"

}