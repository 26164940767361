import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import SendIcon from "@mui/icons-material/Send"
import LinearProgress from "@mui/material/LinearProgress"

import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../interface/ApplicationState"
import { useEffect, useRef, useState } from "react"
import { markReadStart, sendMessageStart } from "../../redux/creators/chatCreators"
import { Bubble } from "./Bubble"


export const ConversationView = () =>
{

  const dispatch = useDispatch()
  const messages = useSelector((state: ApplicationState) => state.chat.messages)
  const currentChatId = useSelector((state: ApplicationState) => state.chat.currentChatId)
  const isSendingMessage = useSelector((state: ApplicationState) => state.chat.isSendingMessage)
  const [ message, setMessage ] = useState("")
  const [ images, setImages ] = useState<string[]>([])
  const listRef = useRef(null);

  const getCurrentChat = () =>
  {
    return messages.find(m => m._id === currentChatId)
  }

  const currentChat = getCurrentChat()

  useEffect(() => {

    scrollToBottom()

    if(currentChat?._id) dispatch(markReadStart(currentChat?._id))

  }, [])


  useEffect(() => {
    scrollToBottom()
  }, [ messages ])

  const handleSendMessage = () =>
  {
    if(!currentChat?.userId) return
    dispatch(sendMessageStart(currentChat?.userId, message, images))
    setMessage("")
  }

  const scrollToBottom = () => {
    // @ts-ignore
    if(listRef && listRef.current) listRef.current.scrollTop = listRef.current.scrollHeight;
  };

  const handleEnter = (e: any) =>
  {
    if(e.keyCode === 13) handleSendMessage()
  }

  return(
    <div>

    <div style={styles.scrollView} ref={listRef}>
    
    { currentChat?.messages.map((message, key) => {
        return (
          <Bubble key={key} message={message}/>
        )
      })}

    </div>
   

      <TextField 
        onKeyDown={handleEnter}
        onClick={() => dispatch(markReadStart(currentChat?._id || ""))}
        InputProps={{
          endAdornment: <IconButton disabled={isSendingMessage || !message} onClick={handleSendMessage}><SendIcon /></IconButton>
        }}
        placeholder="Message" 
        fullWidth
        onChange={(e: any) => setMessage(e.target.value)}
        value={message} />

        { isSendingMessage && <LinearProgress style={styles.sending} /> }

    </div>
  )
}

const styles = {
  scrollView: {
    overflow: "auto",
    height: 500,
  },
  sending: {
    marginLeft: 12,
    marginRight: 12,
    borderRadius: 12,
  }
}