


export enum UserActions
{
  LOGIN_START = "LOGIN_START",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_ERROR = "LOGIN_ERROR",
  CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR",
  GET_USER_PROFILE_START = "GET_USER_PROFILE_START",
  GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR",
  SET_USER_PROFILE_START = "SET_USER_PROFILE_START",
  SET_USER_PROFILE_SUCCESS = "SET_USER_PROFILE_SUCCESS",
  SET_USER_PROFILE_ERROR = "SET_USER_PROFILE_ERROR",
  AUTH_STATE = "AUTH_STATE"
}