import Fab from "@mui/material/Fab"
import Badge from "@mui/material/Badge"
import { useEffect, useState } from "react"
import ChatIcon from '@mui/icons-material/SupportAgent'
import { ApplicationState } from "../interface/ApplicationState"
import { useDispatch, useSelector } from "react-redux"
import { ChatModal } from "../components/chat/ChatModal"
import { variables } from "../const"
import { getMessagesStart } from "../redux/creators/chatCreators"

export const ChatWidget = () =>
{

  const [ showChat, setShowChat ] = useState(false)
  const unreadMessageCount = useSelector((state: ApplicationState) => state.chat.unreadMessageCount)
  const dispatch = useDispatch()


  useEffect(() => {

    dispatch(getMessagesStart())


    setInterval(() => {

      dispatch(getMessagesStart())

    }, variables.POLL_FOR_NEW_MESSAGES)

  }, [])

  return(
    <div style={styles.container}>
    { !showChat && <Badge badgeContent={unreadMessageCount} color="info">
    <Fab
        color="secondary"
        onClick={() => setShowChat(true)}>
        
      <ChatIcon />
      </Fab>
      </Badge> }

      <ChatModal open={showChat} handleClose={() => setShowChat(false)} />
      </div>
  )
}

const styles = {
  container: {
    position: "fixed" as 'fixed',
    bottom: 32,
    left: 32,
    zIndex: 2000,
  }
}