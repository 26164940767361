import Grid from "@mui/material/Grid"
import { DashboardCard } from "./dashboard/DashboardCard"
import { RecentBookings } from "./dashboard/RecentBookings"
import { RecentUsers } from "./dashboard/RecentUsers"
import { useEffect, useState } from "react"
import { DashboardResponse } from "../interface/DashboardResponse"
import { DashboardApi } from "../api/DashboardApi"
import { LoadingPlaceholder } from "../components/LoadingPlaceholder"


export const Dashboard = () =>
{

  const [ loading, setLoading ] = useState(false)
  const [ dashboardResponse, setDashboardResponse ] = useState<DashboardResponse | undefined>()

  useEffect(() => {

    getDashboard(true)

  }, [])


  const getDashboard = async (load: boolean) =>
  {
    try
    {
      if(load) setLoading(true)
      const data = await new DashboardApi().getDashboard()
      setDashboardResponse(data)
      if(load) setLoading(false)
    }
    catch(error)
    {
      if(load) setLoading(false)
    }
  }

  return(
  <div>

    { loading ? <LoadingPlaceholder /> : 
  <>
    <Grid container spacing={3}>

      { dashboardResponse?.headerItems.map((item, key) => <DashboardCard 
      key={key} iconType={item.iconType as any} 
      color={item.color} 
      color2={item.color2} 
      title={item.title} 
      description={item.description} 
      value={item.value} />)}

    </Grid>

    <Grid container spacing={3}>
      <RecentBookings bookings={dashboardResponse?.bookings} getDashboard={getDashboard} />
      <RecentUsers customers={dashboardResponse?.customers} />
    </Grid> 
    </>}
    
  </div>)
}