import List from "@mui/material/List"
import { Chat } from "../../interface/Chat"
import ListItem from "@mui/material/ListItem"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import { setCurrentChat } from "../../redux/creators/chatCreators"
import CardActionArea from "@mui/material/CardActionArea"
import { getInitials } from "../../util/getInitials"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import ListItemText from "@mui/material/ListItemText"
import Badge from "@mui/material/Badge"
import { colors } from "@mui/material"
import { useDispatch } from "react-redux"


interface MessageListProps
{
  messages: Chat[]
  setShowConfirmDeleteModal: (chat: Chat) => void
}

export const MessageList = ({ messages, setShowConfirmDeleteModal } : MessageListProps) =>
{
  const dispatch = useDispatch()

  return(
    <List>
    {messages.map((message, key) => {
         return (<ListItem
         secondaryAction={
           <IconButton edge="end" aria-label="delete" onClick={() => setShowConfirmDeleteModal(message)}>
             <DeleteIcon />
           </IconButton>
         }
       >
         <ListItemAvatar>
          <Badge badgeContent={message.totalUnseenByAdmin} color="info">
           <Avatar style={styles.avatar}>
             {getInitials(message?.email)}
           </Avatar>
           </Badge>
         </ListItemAvatar>
         <CardActionArea onClick={() => dispatch(setCurrentChat(message?._id || ""))}>
         <ListItemText
           primary={message.email || "Unknown user"}
           secondary={`${message.messages[0].text}`}
         />
         </CardActionArea>
       </ListItem>)
    })}
</List>
    )
}


const styles = {
  avatar: {
    backgroundColor: colors.amber[500]
  },
}