import { QuoteItem, QuoteItemTypes } from "../interface/QuoteItem";
import { HttpService } from "./HttpService";


export class QuoteItemApi extends HttpService
{
  get = async (type: QuoteItemTypes) =>
  {
    const response = await this.request<QuoteItem[]>("GET", `quoteConfig?type=${type}`)
    return response?.data
  }

  update = async (glassType: QuoteItem & Required<{ _id: string }>) =>
  {
    const response = await this.request<QuoteItem>("POST", `quoteConfig/update`, glassType)
    return response?.data
  }

  create = async (glassType: QuoteItem) =>
  {
    const response = await this.request<QuoteItem>("POST", `quoteConfig/add`, glassType)
    return response?.data
  }

  delete = async (id: string) =>
  {
    const response = await this.request<QuoteItem>("DELETE", `quoteConfig?id=${id}`)
    return response?.data
  }
}