import { User } from "../../interface/User"
import { UserActions } from "../actions/UserActions"


const initialState: User = {
  email: "",
  name: "",
  isAdmin: false,
  isDarkModeOn: false,
  isAuthed: false,
  isLoggingIn: false,
  isSettingProfile: false,
  loginError: ""
}

export const userReducer = (state = initialState, action: any) =>
{
  switch(action.type)
  {
    case UserActions.LOGIN_START:
      return {
        ...state,
        isLoggingIn: true,
      }
    case UserActions.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        email: action.data.email,
        isDarkModeOn: action.data.isDarkModeOn,
        isAdmin: action.data.isAdmin,
        isAuthed: true,
      }
      case UserActions.LOGIN_ERROR:
        return {
          ...state,
          isLoggingIn: false,
          loginError: action.error,
        }
      case UserActions.CLEAR_LOGIN_ERROR:
        return {
          ...state,
          loginError: ""
        }
      case UserActions.AUTH_STATE:
        return {
          ...state,
          isAuthed: action.data.isAuthed,
        }
      case UserActions.GET_USER_PROFILE_SUCCESS:
        return {
          ...state,
          email: action.data.user?.email,
          name: action.data.user?.name,
          isDarkModeOn: action.data.user?.isDarkModeOn,
          isAdmin: action.data.user?.isAdmin,
        }
      case UserActions.SET_USER_PROFILE_START:
        return {
          ...state,
          isSettingProfile: true,
        }
      case UserActions.SET_USER_PROFILE_SUCCESS:
        return {
          ...state,
          isSettingProfile: false,
          email: action.data.user.email,
          name: action.data.user.name,
          isDarkModeOn: action.data.user.isDarkModeOn
        }
      case UserActions.SET_USER_PROFILE_ERROR:
        return {
          ...state,
          isSettingProfile: false,
          email: action?.data?.user?.email,
          isDarkModeOn: action?.data?.user?.isDarkModeOn
        }
    default:
      return state
  }
}